import {SERVER_URL} from './../../helpers/const';

interface IUser {
    id?: number;
    password?: string;
    password_hash?: string;
    avatar_url?: string;
    company_id?: number;
    phone?: string;
    push_token?: string;
    role?: string;
    access?: boolean;
    login?: string;
    role_id?: number;
    name?: string;
    ref_id?: number;
}

export const UserAPI = {
    register: async (login: string, password_hash: string, role: string, name: string) => {
        const response = await fetch(`${SERVER_URL}/user/reg`, {
            method: 'POST',
            body: JSON.stringify({
                login,
                role,
                name,
                password_hash,
            }),
        });
        const json = await response.json();
        return json;
    },
    create: async (newUser: IUser) => {
        const response = await fetch(`${SERVER_URL}/user/create`, {
            method: 'POST',
            body: JSON.stringify({role_id: 2, ...newUser}),

            headers: {
                Authorization: localStorage.getItem('token') as string,
            },
        });

        const json = await response.json();
        return json;
    },
    login: async (login: string, password: string, code?:string) => {
        // debugger
        try {
            const response = await fetch(`${SERVER_URL}/user/login`, {
                method: 'POST',
                body: JSON.stringify({
                    login,
                    password,
                    code
                }),
            });
            const json = await response.json();
            return json;
        } catch (e) {
            console.log(e, "eafasf");
        }

    },
    get: async (id: string | number) => {

        const response = await fetch(`${SERVER_URL}/user/get/${id}`, {
            headers: {
                Authorization: localStorage.getItem('token') as string,
            },
        });
        const json = await response.json();
        return json;
    },
    update: async (body: IUser) => {
        const response = await fetch(`${SERVER_URL}/user/update`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                Authorization: localStorage.getItem('token') as string,
            },
        });
        const json = await response.json();
        return json;
    },
    checkEmail: async (email:string) => {
        const response = await fetch(`${SERVER_URL}/user/dubl`, {
            method: 'POST',
            body: JSON.stringify({login:email}),
            headers: {
                Authorization: localStorage.getItem('token') as string,
            },
        });
        const json = await response.json();
        console.log(json);
        return json;

    },
    delete: async (body: IUser) => {
        const response = await fetch(`${SERVER_URL}/user/delete`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: {
                Authorization: localStorage.getItem('token') as string,
            },
        });
        const json = await response.json();
        return json;
    },
    getList: async (limit: number = 0, offset: number = 0, query_and: any = null,) => {

        const response = await fetch(`${SERVER_URL}/user/get-list`, {
            method: 'POST',
            body: JSON.stringify({limit, offset, query_and}),
            headers: {
                Authorization: localStorage.getItem('token') as string,
            },
        });
        const json = await response.json();
        return json;
    },
};
