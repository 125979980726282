import React from 'react';
import { BsClipboardData } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/store';
import {AiFillDelete, AiFillEdit, AiFillEye} from "react-icons/ai";
import {deleteEmployee} from "../../store/slices/employeeSlice/employeeSlice";
import {deleteCompany, ICompanyConf} from "../../store/slices/companySlice/companySlice";
import {ICompany} from "../../api/Company/Company";
import usePopup from "../../utils/usePopup";


interface ICardUser {
  //handleOpen: (item: IUserConf) => void;
  item: ICompany;
}

const CardCompany: React.FC<ICardUser> = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const popup = usePopup();
  return (
      <div className='card cursor mb-3'>
          <div className='card-header p-3 pt-2'>
              <div
                  className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
                  style={{width: 40, height: 40}}
              >
                  <div className='mt-1'>
                      <BsClipboardData color='#ffffff' size={25}/>
                  </div>

              </div>
              <div className='text-end ms-5 text-white'>
                <span
                    title='Просмотр'
                     onClick={() => {
                         navigate('/company/' + item.id);
                      }}
                >
                    <AiFillEye size={20}/>
                </span>

                <span title='Удалить'>
                  <AiFillDelete
                  size={20}
                  onClick={() => {
                      popup(()=>dispatch(deleteCompany(item)))

                  }}
                  />
                </span>
              </div>
          </div>

          <div className='card-body mt-3 p-3 pt-0'>
              <div className='d-flex align-items-center justify-content-between flex-wrap'>
                  <span className='text-sm'>#</span>
                  <span className='text-sm'>{item.id}</span>
              </div>
              <div className='d-flex align-items-center justify-content-between flex-wrap'>
                  <span className='text-sm'>Название</span>
                  <span className='text-sm'>{item.name}</span>
              </div>
              <div className='d-flex align-items-center justify-content-between flex-wrap'>
                  <span className='text-sm'>Баланс</span>
                  <span className='text-sm'>{item.balance}</span>
              </div>
              <div className='d-flex align-items-center justify-content-between flex-wrap py-2'>
                  <span className='text-sm'>Доступ</span>
                  <span className={`text-sm px-2 ${item.access ? "paymentStatusGreen" : "paymentStatusRed"}`}>{item.access ? "Доступ есть" : "Доступ заблокирован"}</span>
              </div>
              <div className='d-flex align-items-center justify-content-between flex-wrap'>
                  <span className='text-sm'>Объект</span>
                  <span className='text-sm'>{item.object}</span>
              </div>
              <div className='d-flex align-items-center justify-content-between flex-wrap'>
                  <span className='text-sm'>Пользователи</span>
                  <span className='text-sm'>{item.users}</span>
              </div>
              <div className='d-flex align-items-center justify-content-between flex-wrap'>
                  <span className='text-sm'>Люди</span>
                  <span className='text-sm'>{item.people}</span>
              </div>
          </div>
      </div>
  );
};

export default CardCompany;
