import { SERVER_URL } from './../../helpers/const';
interface IObject {
  grafik_klininga: string;
  name: string;
  ohraniki: string;
  chart: string;
  dlitelnost: string;
  head_of_security: string;
  keyset: string;
  periodicity: string;
  description: string;
  id?: number;
  company_id?: number;
  client_id?: number;
}

export const ObjectAPI = {
  create: async (body: IObject) => {
    const response = await fetch(`${SERVER_URL}/object-secr/create`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  update: async (body: IObject) => {
    const response = await fetch(`${SERVER_URL}/object-secr/update`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  delete: async (body: IObject) => {
    const response = await fetch(`${SERVER_URL}/object-secr/delete`, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  get: async (id: string | number) => {
    const response = await fetch(`${SERVER_URL}/object-secr/get/${id}`, {
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  getList: async (limit: number = 0, offset: number = 0, query_and: any = null) => {
    const response = await fetch(`${SERVER_URL}/object-secr/get-list`, {
      method: 'POST',
      body: query_and
      ? JSON.stringify({ limit, offset, query_and })
      : JSON.stringify({ limit, offset }),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
};
