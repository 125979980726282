import React from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useAppSelector } from '../../store/store';
import UserViewPage from '../UserViewPage/UserViewPage';

const ProfilePage = () => {
  const user = useAppSelector((state) => state.user.user);
  
  return (
    <div>
      <Sidebar />
    </div>
    // <UserViewPage propsId={user.id}/>
  );
};

export default ProfilePage;
