import { Navigate } from 'react-router-dom';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import { useAppSelector } from '../../store/store';

interface IPublicRoute {
  children: JSX.Element;
}

const PublicRoute: React.FC<IPublicRoute> = ({ children, ...rest }) => {
  const token = useAppSelector((state) => state.user.token);
  const loading = useAppSelector((state) => state.user.loading);
  if (loading) {
    return <LoadingPage />;
  }

  if (token) {
    return <Navigate to='/' replace={true} />;
  }

  return children;
};

export default PublicRoute;
