// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//
// export interface UserState {
//   token: string | null;
//   user: any;
//   loading: boolean;
//   data: IUserConf[];
//   total: number;
//   currentUser?: IUserConf;
//   error?: string;
// }
//
// interface IPagination {
//   limit: number;
//   offset: number;
// }
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CompanyAPI, ICompany} from "../../../api/Company/Company";
import {EmployeeAPI} from "../../../api/Employee/EmployeeAPI";
import {IEmployeeConf} from "../employeeSlice/employeeSlice";


export interface CompanyState {
    data: ICompanyConf[];
    total: number;
    currentCompany?: ICompanyConf;
    isRequested: boolean;
}

export interface ICompanyConf {
    id?: number;
    name?: string;
    balance?: number;
    access?: string;
    object?:number;
    users?:number;
    people?:number;
}

interface IPagination {
    limit: number;
    offset: number;
    query_and?: any;

}

const initialState: CompanyState = {
    data: [],
    total: 0,
    currentCompany: undefined,
    isRequested: false,
};

export const getCompany = createAsyncThunk(
    'company/getCompany',
    async (id: string | number, thunkAPI) => {
        const response = await CompanyAPI.get(id);

        return response;
    }
);
export const getCompanyList = createAsyncThunk(
    'company/getCompanyList',
    async ({ limit, offset, query_and }: IPagination) => {
        const response = await CompanyAPI.getList( limit, offset, query_and );

        return response;
    }
);
export const deleteCompany = createAsyncThunk(
    'company/deleteCompany',
    async (data: ICompany, thunkAPI) => {

        const response = await CompanyAPI.delete(data);
        return { ...response, item: data };
    }
);
export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        resetCompanyRequested(state){
            state.isRequested = false;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getCompany.fulfilled, (state, action) => {

            if (action.payload.status) {
                state.currentCompany = action.payload.data;
            }
        });
        builder.addCase(deleteCompany.fulfilled, (state, action) => {

            if (action.payload.status) {
                state.data = state.data.filter((i) => i.id != action.payload.item.id);
            }
        });
        builder.addCase(getCompanyList.fulfilled, (state, action) => {

            if (action.payload.status && action.payload.data) {
                state.data = action.payload.data;
                state.total = action.payload.count;
                state.isRequested = true;
            }
        });
    },
});
export const { resetCompanyRequested} = companySlice.actions;

export default companySlice.reducer;