import React, {useEffect, useState} from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import CardEmployee from "../../components/CardEmployee/CardEmployee";
import {roles} from "../../navigation/navigation";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {UserAPI} from "../../api/User/User";
import {CompanyAPI} from "../../api/Company/Company";
import Pagination from "../../components/Pagination/Pagination";
import CardCompany from "../../components/CardCompany/CardCompany";
import {getUsers} from "../../store/slices/userSlice/userSlice";
import {getCompany, getCompanyList} from "../../store/slices/companySlice/companySlice";
import {useNavigate} from "react-router-dom";

const RegisteredCompanies = () => {
    const user = useAppSelector((state) => state.user.user);
    const companies = useAppSelector((state) => state.company.data);
    const [offset, setOffset] = useState(0);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    //const total = useAppSelector((state) => state.user.total);
    const total = useAppSelector((state) => state.company.total);
  //  const [companies, setCompanies] = useState([])
    const access = [roles.admin].includes(user?.role) || user.company_id===1;
    useEffect(() => {
        console.log(total);
        if(!access)
            navigate('/');

        dispatch(getCompanyList({ limit: 10, offset: offset}));
        // CompanyAPI.getList(10, offset)
        //     .then(res => {
        //        // setCompanies(res.data)
        //         setTotal(res.count)
        //     })
    },[])


useEffect(() => {
    dispatch(getCompanyList({ limit: 10, offset: offset}));
    // CompanyAPI.getList(10, offset).then(res => setCompanies(res.data))
}, [offset]);
return (
    <div>
        <Sidebar/>
        <div className='avoidSidebar py-3 pe-3 ps-sm-3'>
            <div className='row'>
                {
                    companies.map((compony:any, index) =>
                        <div className='col-12 col-md-4' key={index}>
                            <CardCompany item={compony}/>
                        </div>
                    )
                }
            </div>

        </div>
        <Pagination
            offset={offset}
            total={total}
            handleBack={() => {
                if (offset <= 0) return;
                setOffset(offset - 10);
            }}
            handleNext={() => {
                if (offset > total) return;
                setOffset(offset + 10);
            }}
            handleChose={(page)=>{
                setOffset(page);
            }}
        />

    </div>
);
}
;

export default RegisteredCompanies;
