import React, { useEffect, useState } from 'react';
import { BsClipboardData, BsMap } from 'react-icons/bs';
import {useNavigate, useParams} from 'react-router-dom';
import CardEvent from '../../components/CardEvent/CardEvent';
import Maps from '../../components/Maps/Maps';
import Modal from '../../components/Modal/Modal';
import ModalEmployee from '../../components/ModalEmployee/ModalEmployee';
import Sidebar from '../../components/Sidebar/Sidebar';
import { SERVER_URL } from '../../helpers/const';
import { roles } from '../../navigation/navigation';
import left from "../../assets/img/left.svg"
import right from "../../assets/img/right.svg"
import { getEmployee, getEmployees } from '../../store/slices/employeeSlice/employeeSlice';
import { getEvents } from '../../store/slices/eventSlice/eventSlice';
import {
  getObject,
  getObjects,
} from '../../store/slices/objectSlice/objectSlice';
import { getShifts } from '../../store/slices/shiftSlice/shiftSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import {getCompany, getCompanyList} from "../../store/slices/companySlice/companySlice";
import {CompanyAPI, ICompany} from "../../api/Company/Company";
import {FinanceAPI} from "../../api/Finance/Finance";
import {getFinanceList} from "../../store/slices/financeSlice/financeSlice";
import CardFinance from "../../components/CardFinance/CardFinance";
import {getUsers} from "../../store/slices/userSlice/userSlice";
import CardObject from "../../components/CardObject/CardObject";
import ModalObject from "../../components/ModalObject/ModalObject";
import CardEmployee from "../../components/CardEmployee/CardEmployee";
import CardUser from "../../components/CardUser/CardUser";
import CardReport from "../../components/CardReport/CardReport";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display:false,
    },

  },
  scales: {
    x: {
      ticks: {
        color: 'white', // Цвет меток по оси X
      },
      grid: {
        color: 'rgba(255,255,255,0.2)', // Цвет задней сетки по оси X
      },
    },
    y: {
      ticks: {
        color: 'white', // Цвет меток по оси Y
      },
      grid: {
        color: 'rgba(255,255,255,0.2)', // Цвет задней сетки по оси X
      },
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','May', 'June', 'July'];


interface IFinanceConf{
  create_at: string,

}
const monthsArray = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
const ReportViewPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [month, setMonth] = useState(new Date().getMonth())
  const employee = useAppSelector((state) => state.employee.total);
  const companyCount = useAppSelector((state) => state.company.total);
  const companies = useAppSelector((state) => state.company.data).filter((company)=>company.balance==0).length;
  const finance = useAppSelector((state) => state.finance.data);
  const financeMonth = finance.filter(fil=>new Date(fil.create_at).getMonth()==month);
  console.log(financeMonth);
  let sumInMonth = 0;
  let sumInBalance=0;
  const ChartData:number[] = [];
  const labelsData:string[]=[];
  finance.map(fin=>{
    if(fin.type === "pending")
      return
    sumInBalance+=fin.summa;
  })
  const st = useAppSelector((state) => state);
  finance.map((fin) => {
    let currentDate = month;
    let temp = new Date(fin.create_at).getMonth();
    console.log(currentDate, temp);
    if(currentDate==temp)
      sumInMonth+=fin.summa;
  })

  let months=[];
  let months2=[];
  for(let i=0;i<31;i++)
    months.push(0)
  for(let i=1;i<32;i++)
    months2.push(i.toString())
  const dataSums=months;

  financeMonth.forEach(fin=>{
    const key =  new Date(fin.create_at).getDate()-1;

    dataSums[key] = (dataSums[key] || 0) + fin.summa;

    // ChartData.push(fin.summa);
     labelsData.push(new Date(fin.create_at).getDay().toString())
  })
  console.log(ChartData);
  console.log(labelsData);
  console.log(dataSums);


  useEffect(() => {
    console.log(st);

  }, [st]);
  useEffect(() => {
    CompanyAPI.get(id!).then(res=>console.log(res))

  }, []);

  const user = useAppSelector((state) => state.user.user);
  const objects = useAppSelector((state) => state.object.total);
  const users = useAppSelector((state) => state.user.total);

  const data = {
    labels:months2,
    datasets: [
      {
        fill: true,
        label: 'Сумма платежей: ',
        color:'white',
        data:dataSums,
        borderColor: 'white',
        pointHitRadius: 100,
        pointRadius: 5,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };



  useEffect(() => {
    if (!user) return;
    if (user.role === roles.admin) {

        dispatch(getFinanceList({ limit: 100, offset: 0}));
        dispatch(getObjects({ limit: 100, offset: 0}));
        dispatch(getEmployees({ limit: 100, offset: 0}));
        dispatch(getUsers({ limit: 100, offset: 0}));
        dispatch(getCompanyList({ limit: 100, offset: 0}));

    }
  }, [user]);

  const navigate = useNavigate()
  const access = [roles.admin].includes(user.role) || user.company_id===1;
  useEffect(() => {
    if(!access)
      navigate('/');
  }, []);

  console.log(finance);
  return (
    <div>
      <Sidebar />
      <div className='avoidSidebar py-3 pe-3 ps-sm-3'>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <h6 className='m-0'>Отчёт</h6>

        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='card cursor mb-3'>

            </div>
          </div>
          <div className="d-flex gap-3 w-100 cardWrapper">
            <CardReport header="Количество компаний" count={companyCount.toLocaleString()} Img={BsClipboardData}/>
            <CardReport header="Количество не оплативших аккаунтов" count={companies.toLocaleString()} Img={BsClipboardData}/>
            <CardReport header="Количество объектов" count={objects.toLocaleString()} Img={BsClipboardData}/>
            <CardReport header="Количество охраников" count={employee.toLocaleString()} Img={BsClipboardData}/>
            <CardReport header="Количество пользователей" count={users.toLocaleString()} Img={BsClipboardData}/>
            <CardReport header="Сумма на балансах" count={sumInBalance.toLocaleString()} mode="₽" Img={BsClipboardData}/>
            <CardReport header="Сумма за месяц" count={sumInMonth.toLocaleString()}  mode="₽" Img={BsClipboardData}/>

          </div>
          <div className="Chart">
            <h3>
              <img onClick={()=>{
                console.log(month);
                if(month!==0)
                  setMonth(month-1)
                else
                  setMonth(11)
              }} src={left} alt=""/>
              {" График платежей"} {monthsArray[month]} {" "}
              <img onClick={()=>{
                console.log(month);
                if(month!==11)
                  setMonth(month+1)
                else
                  setMonth(0)
              }} src={right} alt=""/></h3>
            <Line options={options} data={data} />;
          </div>

        </div>

      </div>

    </div>
  );
};

export default ReportViewPage;
