// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//
// export interface UserState {
//   token: string | null;
//   user: any;
//   loading: boolean;
//   data: IUserConf[];
//   total: number;
//   currentUser?: IUserConf;
//   error?: string;
// }
//
// interface IPagination {
//   limit: number;
//   offset: number;
// }
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CompanyAPI} from "../../../api/Company/Company";
import {EmployeeAPI} from "../../../api/Employee/EmployeeAPI";
import {IEmployeeConf} from "../employeeSlice/employeeSlice";
import {FinanceAPI} from "../../../api/Finance/Finance";


export interface FinanceState {
    data: IFinanceConf[];
    total: number;
    currentFinance?: IFinanceConf;
    isRequested: boolean;
}

export interface IFinanceConf{
    id:number,
    summa:number,
    type:string,
    create_at:string,
    company_id: number,
    confirmation_url:string,
    document:string,
    group:string,
    kommentarii:string,
    out_id:number,
    purpose:string,
    purse:string,
    uroven:string,
    yid:string,
}

interface IPagination {
    limit: number;
    offset: number;
    query_and?: any;
    query?: any;
}

const initialState: FinanceState = {
    data: [],
    total: 0,
    currentFinance: undefined,
    isRequested: false,
};

export const getFinance = createAsyncThunk(
    'company/getFinance',
    async (id: string | number, thunkAPI) => {
        const response = await FinanceAPI.get(id);

        return response;
    }
);
export const getFinanceList = createAsyncThunk(
    'company/getFinanceList',
    async ({limit, offset, query_and}:IPagination) => {
        const response = await FinanceAPI.getList(limit, offset, query_and);

        return response;
    }
);
// export const deleteCompany = createAsyncThunk(
//     'company/deleteCompany',
//     async (data: IEmployeeConf, thunkAPI) => {
//         const response = await CompanyAPI.delete(data);
//         return { ...response, item: data };
//     }
// );
export const financeSlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        resetCompanyRequested(state){
            state.isRequested = false;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getFinance.fulfilled, (state, action) => {

            if (action.payload.status) {
                state.currentFinance = action.payload.data;
            }
        });
        builder.addCase(getFinanceList.fulfilled, (state, action) => {

            if (action.payload.status) {
                state.data = action.payload.data;
            }
        });
    },
});
export const { resetCompanyRequested} = financeSlice.actions;

export default financeSlice.reducer;