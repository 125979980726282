import { SERVER_URL } from '../../helpers/const';

export const FileAPI = {
  upload: async (file: File) => {
    let form_data = new FormData()
    form_data.append('file', file)

    const response = await fetch(`${SERVER_URL}/upload`, {
      method: 'POST',
      body: form_data,
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json: { response: number, status: string, url: string } = await response.json();
    return json;
  }
};
