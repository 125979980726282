import { EmployeeAPI } from './../../../api/Employee/EmployeeAPI';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export interface EmployeeState {
  data: IEmployeeConf[];
  total: number;
  currentEmployee?: IEmployeeConf;
  isRequested: boolean;
}

export interface IEmployeeConf {
  id?: number;
  password?: string;
  step?: number;
  token?: string;
  company_id?: number;
  login?: string;
  name?: string;
  avatar_url?: string;
  on_change?: boolean;
  role?: string;
  photo?: string;
  telegram?: number;
  connected?: boolean;
  date_of_birth?: string;
  number?: string;
  password_hash?: string;
}

interface IPagination {
  limit: number;
  offset: number;
  query_and?: any;
  query?: any;
}

const initialState: EmployeeState = {
  data: [],
  total: 0,
  currentEmployee: undefined,
  isRequested: false,
};

export const getEmployees = createAsyncThunk(
  'employee/getEmployees',
  async ({ limit, offset, query_and, query }: IPagination, thunkAPI) => {
    const response = await EmployeeAPI.getList(limit, offset, query_and, query);
    return response;
  }
);

export const createEmployee = createAsyncThunk(
  'employee/createEmployee',
  async (data: IEmployeeConf, thunkAPI) => {
    const response = await EmployeeAPI.create(data);
    return response;
  }
);

export const updateEmployee = createAsyncThunk(
  'employee/updateEmployee',
  async (data: IEmployeeConf, thunkAPI) => {
    const response = await EmployeeAPI.update(data);
    return response;
  }
);

export const deleteEmployee = createAsyncThunk(
  'employee/deleteEmployee',
  async (data: IEmployeeConf, thunkAPI) => {
    const response = await EmployeeAPI.delete(data);
    return { ...response, item: data };
  }
);

export const getEmployee = createAsyncThunk(
  'employee/getEmployee',
  async (id: string | number, thunkAPI) => {
    const response = await EmployeeAPI.get(id);
    return response;
  }
);

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    resetEmployeesRequested(state){
      state.isRequested = false;
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      if (action.payload.status && action.payload.data) {
        state.data = action.payload.data;
        state.total = action.payload.count;
        state.isRequested = true;
      }
    });
    builder.addCase(createEmployee.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data.push(action.payload.employee);
      }
    });
    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data = state.data.map((item) =>
          item.id == action.payload.employee.id ? action.payload.employee : item
        );
        state.currentEmployee = action.payload.employee;
      }
    });
    builder.addCase(deleteEmployee.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data = state.data.filter((i) => i.id != action.payload.item.id);
      }
    });
    builder.addCase(getEmployee.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.currentEmployee = action.payload.data;
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetEmployeesRequested} = employeeSlice.actions;

export default employeeSlice.reducer;
