import React, { useState } from 'react';
import { MdAdd, MdDelete } from 'react-icons/md';
import Input from '../Input/Input';
import Select from '../Select/Select';


export interface IAddress {
  street: string;
  house: string;
}

interface IAddAddressesForm {
  items: Array<IAddress>;
  handleChange: (i: any) => void;
}


const AddAddressesForm: React.FC<IAddAddressesForm> = ({ items, handleChange }) => {
  const [street, setStreet] = useState('');
  const [house, setHouse] = useState('');


  const handleAddressAdd = () => {
    handleChange([...items, { street, house }]);
    setStreet('');
    setHouse('');
  };

  const handleAddressRemove = (address: IAddress) => {
    handleChange(items.filter((a: any) => a.street !== address.street && a.house !== address.house));
  };

  return (
    <div>
      <p className='mb-1 text-sm'>Адреса</p>
      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div style={{ width: '70%', paddingRight: '10px' }}>
          <Input
            value={street}
            handleChange={(e: any) => {
              setStreet(e.currentTarget.value);
            }}
            placeholder='Улица'
          />
        </div>
        <div className='w-25'>
          <Input
            value={house}
            handleChange={(e: any) => {
              setHouse(e.currentTarget.value);
            }}
            placeholder='Дом'
          />
        </div>
        <div className='text-info cursor ms-2'>
          <MdAdd size={25} onClick={handleAddressAdd} />
        </div>
      </div>
      {items.map((a: IAddress, index: number) => (
        <div className='d-flex align-items-center justify-content-between mt-3' key={index}>
          <div style={{ width: '70%', paddingRight: '10px' }}>
            <Input
              value={a.street}
              disabled
              handleChange={(e: any) => {
                // setRoom(e.currentTarget.value);
              }}
              placeholder='Улица'
            />
          </div>
          <div className='w-25'>
            <Input
              value={a.house}
              disabled
              handleChange={(e: any) => {
                // setRoom(e.currentTarget.value);
              }}
              placeholder='Дом'
            />
          </div>
          <div className='text-info cursor ms-2'>
            <MdDelete size={23} onClick={() => handleAddressRemove(a)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddAddressesForm;
