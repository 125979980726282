import React, {useState} from 'react';
import {BiMoneyWithdraw} from "react-icons/bi";
import {FinanceAPI} from "../../api/Finance/Finance";
import {Link} from "react-router-dom";
import {useAppSelector} from "../../store/store";

interface IFinanceConf{
  id:number,
  summa:number,
  type:string,
  create_at:string | Date,
  company_id: number,
  confirmation_url:string,
  document:string,
  group:string,
  kommentarii:string,
  out_id:number,
  purpose:string,
  purse:string,
  uroven:string,
  yid:string,
}
interface ICardFinance {
  //handleOpen: (item: IUserConf) => void;
  item: IFinanceConf;
  access?:boolean;
  companyName?:string;
}

const CardFinance: React.FC<ICardFinance> = ({ item, access, companyName }) => {
  const [status, setStatus] = useState(false);
  return (
    <div className='card cursor mb-3'>
      <div className='card-header p-3 pt-2 d-flex justify-between'>
        <div
          className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
          style={{ width: 40, height: 40 }}
        >
          <div className='mt-1'>
            <BiMoneyWithdraw color='#ffffff' size={25} />
          </div>
        </div>
        <button className={ item.type !=="pending" ? "paymentStatusGreen": !status ? "paymentStatusRed":"paymentStatusGreen"}
                onClick={()=>{
                  if (!access || item.type ==="in")
                    return
                  FinanceAPI.changeStatus(item.id).then(res => {
                   console.log(res);
                   setStatus(true)
                  });
                }}
        >{ item.type !=="pending" ? "оплачен": !status ?"не оплачен":"оплачен"}</button>

      </div>
      <div className='card-body mt-3 p-3 pt-0'>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>#</span>
          <span className='text-sm'>{item.id}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Сумма</span>
          <span className='text-sm'>{item.summa}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Компания</span>
          <span className='text-sm'>{companyName}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Дата</span>
          <span className='text-sm'>{new Date(item.create_at!).toLocaleString('ru-RU')}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <Link to={item.confirmation_url} className='text-sm'>Проверить платёж</Link>

        </div>
      </div>
    </div>
  );
};

export default CardFinance;
