import React from 'react';
import { BsClipboardData } from 'react-icons/bs';
import { AiFillEye, AiFillEdit, AiFillDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { deleteUser, IUserConf } from '../../store/slices/userSlice/userSlice';
import { useAppDispatch } from '../../store/store';
import usePopup from "../../utils/usePopup";

interface ICardUser {
  handleOpen: (item: IUserConf) => void;
  item: IUserConf;
  mode?:string
}

const CardUser: React.FC<ICardUser> = ({ handleOpen, item,mode }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const popup = usePopup();
  return (
    <div className='card cursor mb-3 userCard'>
      <div className='card-header p-3 pt-2'>
        <div
          className='icon icon-lg icon-shape  bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
          style={{ width: 40, height: 40 }}
        >
          <div className='mt-1'>
            <BsClipboardData color='#ffffff' size={25} />
          </div>
        </div>
        <div className='text-end ms-5 text-white'>
          {
            mode !== "inCompany" &&
            <span
                title='Просмотр'
                onClick={() => {
                  navigate('/user/' + item.id);
                }}
            >
            <AiFillEye size={20}/>
          </span>
          }
          <span
              title='Изменить'
              className='mx-1'
              onClick={(e) => {
                e.stopPropagation();
                handleOpen(item);
              }}
          >
            <AiFillEdit size={20} />
          </span>
          {
            mode !== "inCompany" &&
            <span title='Удалить'>
            <AiFillDelete
                size={20}
                onClick={() => {
                  popup(()=> dispatch(deleteUser(item)))

                }}
            />
          </span>
          }
        </div>
      </div>
      <div className='card-body mt-0 p-3 pt-0'>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>#</span>
          <span className='text-sm'>{item.id}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Логин</span>
          <span className='text-sm'>{item.login}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>ФИО</span>
          <span className='text-sm'>{item.name}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Телефон</span>
          <span className='text-sm'>{item.phone}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Роль</span>
          <span className='text-sm'>{item.role}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Онлайн</span>
          <span className='text-sm'></span>
        </div>
      </div>
    </div>
  );
};

export default CardUser;
