export const validationPassword = (password: string) => {
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (password.length < 8) {
        return 'Пароль должен быть не менее 8 символов';
    }

    if (!uppercaseRegex.test(password)) {
        return 'Пароль должен содержать как минимум одну заглавную букву';
    }

    if (!lowercaseRegex.test(password)) {
        return 'Пароль должен содержать как минимум одну строчную букву';
    }

    if (!digitRegex.test(password)) {
        return 'Пароль должен содержать как минимум одну цифру';
    }

    if (!specialCharRegex.test(password)) {
        return 'Пароль должен содержать как минимум один специальный символ';
    }

    return null;
}

