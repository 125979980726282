import { ObjectAPI } from './../../../api/Object/Object';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export interface ObjectState {
  data: IObjectConf[];
  total: number;
  currentItem?: IObjectConf;
  isRequested: boolean;
}

export interface IObjectConf {
  grafik_klininga: string;
  name: string;
  ohraniki: string;
  chart: string;
  dlitelnost: string;
  head_of_security: string;
  keyset: string;
  address: string;
  periodicity: string;
  description: string;
  id?: number;
  company_id?: number;
  client_id?: number;
}

interface IPagination {
  limit: number;
  offset: number;
  query_and?: any;
}

const initialState: ObjectState = {
  data: [],
  total: 0,
  currentItem: undefined,
  isRequested: false,
};

export const getObjects = createAsyncThunk(
  'object/getObjects',
  async ({ limit, offset, query_and }: IPagination, thunkAPI) => {
    const response = await ObjectAPI.getList(limit, offset, query_and);
    return response;
  }
);

export const createObject = createAsyncThunk(
  'object/createObject',
  async (data: IObjectConf, thunkAPI) => {
    const response = await ObjectAPI.create(data);
    return response;
  }
);

export const updateObject = createAsyncThunk(
  'object/updateObject',
  async (data: IObjectConf, thunkAPI) => {
    const response = await ObjectAPI.update(data);
    return response;
  }
);

export const deleteObject = createAsyncThunk(
  'object/deleteObject',
  async (data: IObjectConf, thunkAPI) => {
    const response = await ObjectAPI.delete(data);
    return { ...response, item: data };
  }
);

export const getObject = createAsyncThunk(
  'object/getObject',
  async (id: string | number, thunkAPI) => {
    const response = await ObjectAPI.get(id);
    return response;
  }
);

export const objectSlice = createSlice({
  name: 'object',
  initialState,
  reducers: {
    resetObjectsRequested(state){
      state.isRequested = false;
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getObjects.fulfilled, (state, action) => {
      if (action.payload.status && action.payload.data) {
        state.data = action.payload.data;
        state.total = action.payload.count;
        state.isRequested = true;
      }
    });
    builder.addCase(createObject.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data.push(action.payload.objectSecr);
      }
    });
    builder.addCase(updateObject.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data = state.data.map((item) =>
          item.id == action.payload.objectSecr.id
            ? action.payload.objectSecr
            : item
        );
        state.currentItem = action.payload.objectSecr;
      }
    });
    builder.addCase(deleteObject.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data = state.data.filter((i) => i.id != action.payload.item.id);
      }
    });
    builder.addCase(getObject.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.currentItem = action.payload.data;
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {resetObjectsRequested } = objectSlice.actions;

export default objectSlice.reducer;
