import { CheckingAPI } from './../../../api/Checking/CheckingAPI';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export interface CheckingState {
  data: ICheckingConf[];
  total: number;
  isRequested: boolean;
}

export interface ICheckingConf {
  id?: number;
  object_id?: number;
  company_id?: number;
  coords: string;
  datetime_end: string;
  datetime_start: string;
  guard_id?: number;
  info?: string;
}

interface IPagination {
  limit: number;
  offset: number;
  query_and?: any;
  query?: any;
}

const initialState: CheckingState = {
  data: [],
  total: 0,
  isRequested: false,
};

export const getCheckings = createAsyncThunk(
  'checking/getCheckings',
  async ({ limit, offset, query_and, query }: IPagination, thunkAPI) => {
    const response = await CheckingAPI.getList(limit, offset, query_and, query);
    return response;
  }
);

export const createChecking = createAsyncThunk(
  'checking/createChecking',
  async (data: ICheckingConf, thunkAPI) => {
    const response = await CheckingAPI.create(data);
    return response;
  }
);

export const updateChecking = createAsyncThunk(
  'checking/updateChecking',
  async (data: ICheckingConf, thunkAPI) => {
    const response = await CheckingAPI.update(data);
    return response;
  }
);

export const deleteChecking = createAsyncThunk(
  'checking/deleteChecking',
  async (data: ICheckingConf, thunkAPI) => {
    const response = await CheckingAPI.delete(data);
    return { ...response, item: data };
  }
);

export const CheckingSlice = createSlice({
  name: 'checking',
  initialState,
  reducers: {
    resetCheckingsRequested(state){
      state.isRequested = false;
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getCheckings.fulfilled, (state, action) => {
      if (action.payload.status && action.payload.data) {
        state.data = action.payload.data;
        state.total = action.payload.count;
        state.isRequested = true;
      }

    });
    builder.addCase(createChecking.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data.push(action.payload.checking);
      }
    });
    builder.addCase(updateChecking.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data = state.data.map((item) =>
          item.id == action.payload.checking.id ? action.payload.checking : item
        );
      }
    });
    builder.addCase(deleteChecking.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data = state.data.filter((i) => i.id != action.payload.item.id);
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetCheckingsRequested} = CheckingSlice.actions;

export default CheckingSlice.reducer;
