import React, { useState } from 'react';
import { BsClipboardData } from 'react-icons/bs';
import Zoom from 'react-medium-image-zoom';
import { SERVER_URL } from '../../helpers/const';
import { IEventConf } from '../../store/slices/eventSlice/eventSlice';
import { useAppSelector } from '../../store/store';
import placeholder from './../../assets/img/placeholder.png';

interface ICardEvent {
  item: IEventConf;
  fullWidth?: boolean;
}

const CardEvent: React.FC<ICardEvent> = ({ item, fullWidth = false }) => {
  const employees = useAppSelector((state) => state.employee.data);
  const object = useAppSelector((state) => state.object.data);
  const [active, setActive] = useState(false);

  const getLabel = (key: string) => {
    switch (key) {
      case 'comment':
        return 'Комментарий';
      case 'event':
        return 'Событие';
      case 'photos':
        return 'Фотографии';
      case 'toWho':
        return 'Адресовано';
      case 'reason':
        return 'Цель/Причина';
      case 'apartment':
        return 'Номер квартиры';
      case 'report':
        return 'Замечания';
      case 'fixed':
        return 'Возможно устранить';
      case 'act':
        return 'Акт выявленного нарушения';
      case 'name':
        return 'ФИО';
      case 'room':
        return 'Помещение';
      case 'keys':
        return 'Ключи';
      case 'polisValue':
        return 'Данные полиса ОСАГО';
      case 'address':
        return 'Адрес';
      case 'phone':
        return 'Номер телефона';
      case 'time':
        return 'Время';
      case 'reported':
        return 'На кого жалоба';
      case 'building':
        return 'Дом';
      case 'sound':
        return 'Характер звука';
      case 'result':
        return 'Результат о проделанной работе';
      case 'to_who':
        return 'Обратился';
      case 'allowed':
        return 'Получил разрешение';
      case 'audio':
        return 'Аудио';
      case 'total_time':
        return 'Время';
      case 'distance':
        return 'Расстояние';
      case 'events_count':
        return 'Выявлено событий/замечаний';
      case 'street':
        return 'Улица';
      case 'type':
        return 'Тип транспорта';
      case 'passport':
        return 'Номер водительского удостоверения';
      case 'property':
        return 'Порча имущества';
      case 'strangers':
        return 'Посторонние';
      case 'fight':
        return 'Драка';
      case 'police':
        return 'Вызвал полицию';
      case 'conversation':
        return 'Провел беседу';
      case 'parking':
        return 'Водитель перепарковался';
      case 'instruction':
        return 'Инструктаж';
      case 'door':
        return 'Целостность входной двери';
      case 'garbage':
        return 'Отсутствие мусора';
      case 'wall':
        return 'Целостность стен';
      case 'elevator':
        return 'Исправность лифта';
      case 'coord':
        return 'Геолокация';
      case 'fromBuilding':
        return 'Дом уведомителя';
      case 'fromApartment':
        return 'Квартира уведомителя';
      case 'fromStreet':
        return 'Улица уведомителя';
      case 'datetime_end':
        return 'Дата и время конца';
      case 'number':
        return 'Номер';
      case 'numberType':
        return 'Тип номера';
      case 'trafficAccidentLeave':
        return 'Оставление места ДТП';
      case 'notifyList':
        return 'Известил(а)';
      default:
        return key;
    }
  };

  if (!fullWidth) {
    const getInfo = (info: string) => {
      if (info[0] === '{') {
        let items = [];
        let data: any = {};
        try {
          data = JSON.parse(info);
        } catch (error) {
          console.log(error);
        }
        for (let key in data) {
          if (typeof data[key] === 'string' || typeof data[key] === 'number') {
            items.push({ value: data[key], key });
          } else if (typeof data[key] === 'boolean') {
            items.push({ value: data[key] === true ? 'Да' : 'Нет', key });
          } else {
            items.push({ value: data[key], key });
          }
        }
        // Special properties:
        items = items.filter(({ key }) => !['step', 'alerted', 'datetime_start', 'datetime_end'].includes(key))
        // 
        return items.map((i, index) => (
          <div
            className='d-flex align-items-center justify-content-between flex-wrap'
            key={index}
          >
            <span className='text-sm'>{getLabel(i.key)}</span>
            {i.key === 'audio' ? (
              <audio
                src={`${SERVER_URL}/get-file/${i.value}`}
                controls
                className='w-100 mt-1 mb-2'
                style={{ height: 30 }}
              ></audio>
            ) : i.key === 'keys' || i.key === 'notifyList' ? (
              <div>
                {i.value.map((k: any, inx: any) => (
                  <div className='text-sm' key={inx}>
                    {k.type} - {k.value === true ? 'да' : 'нет'}
                  </div>
                ))}
              </div>
            ) : (
              <span className='text-sm mw-100'>{i.value || '-'}</span>
            )}
          </div>
        ));
      } else {
        return <span className='text-sm'>{info}</span>;
      }
    };

    return (
      <div
        className='card cursor card-event h-100'
        onClick={() => {
          setActive(!active);
        }}
      >
        <div className='card-header p-3 pt-2'>
          <div
            className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
            style={{ width: 40, height: 40 }}
          >
            <div className='mt-1'>
              <BsClipboardData color='#ffffff' size={25} />
            </div>
          </div>
          <div className='text-end ms-5 text-sm text-white'>
            {item.event || 'Событие'}
          </div>
        </div>
        <div className='card-body mt-0 p-3 pb-0 mb-0'>
          <div className='d-flex align-items-center justify-content-between flex-wrap'>
            <span className='text-sm'>Охраник</span>
            <span className='text-sm'>
              {employees.find((i) => i.id == item.guard_id)?.name ||
                item.guard_id}
            </span>
          </div>
          <div className='d-flex align-items-center justify-content-between flex-wrap'>
            <span className='text-sm'>Объект</span>
            <span className='text-sm'>
              {object.find((i) => i.id == item.object_id)?.name || item.object_id}
            </span>
          </div>
          <div className='d-flex align-items-center justify-content-between flex-wrap'>
            <span className='text-sm'>Дата и время</span>
            <span className='text-sm'>
              {new Date(item.create_at!).toLocaleString('ru-RU')}
            </span>
          </div>
          <div className='d-flex align-items-center justify-content-between flex-wrap'>
            <span className='text-sm'>Тема</span>
            <span className='text-sm'>{item.info_subject}</span>
          </div>
          <div className='d-flex align-items-center justify-content-between flex-wrap'>
            <span className='text-sm'>Информация</span>
          </div>
          {active && getInfo(item.info!)}
        </div>
        <div className='card-footer mt-0 px-3 pt-0'>
          {active && (
            <div className='mt-1' onClick={(e) => e.stopPropagation()}>
              {item.photo && JSON.parse(item.photo)?.filter((photo: string) => photo).length > 0 ? (
                <div className='d-flex align-items-center hideScroll overflowX image-container'>
                  {JSON.parse(item.photo).filter((photo: string) => photo).map((photo: any, index: number) => (
                    <Zoom>
                      <img
                        key={index}
                        src={`${SERVER_URL}/get-file/${photo}`}
                        alt='preview'
                        className='w-100 card-image'
                      />
                    </Zoom>
                  ))}
                </div>
              ) : (
                <Zoom>
                  <img src={placeholder} alt='preview' className='w-100' />
                </Zoom>
              )}
            </div>
          )}
        </div>
      </div>
    );
  } else {

    const getInfo = (info: string) => {
      if (info[0] === '{') {
        let items = [];
        let data: any = {};
        try {
          data = JSON.parse(info);
        } catch (error) {
          console.log(error);
        }
        for (let key in data) {
          if (key === 'datetime_end') {
            items.push({ value: new Date(data[key]!).toLocaleString('ru-RU'), key });
          } else if (typeof data[key] === 'string' || typeof data[key] === 'number') {
            items.push({ value: data[key], key });
          } else if (typeof data[key] === 'boolean') {
            items.push({ value: data[key] === true ? 'Да' : 'Нет', key });
          } else {
            items.push({ value: data[key], key });
          }
        }
        // Special properties:
        items = items.filter(({ key }) => !['step', 'alerted', 'datetime_start'].includes(key))
        // 
        return items.map((i, index) => (
          i.key === 'audio' && !i.value ? <></> :
            <div style={i.key === 'audio' ? { width: 270 } : {}}
              className={'d-flex flex-column align-items-center justify-content-between flex-wrap mt-1 ' + (i.key === 'audio' ? '' : 'mx-3')}
              key={index}
            >
              <span className='text-sm text-white'>{getLabel(i.key)}</span>
              {
                i.key === 'audio' ? (
                  <audio
                    src={`${SERVER_URL}/get-file/${i.value}`}
                    controls
                    className='w-100 mt-1 mb-2'
                    style={{ height: 30 }}
                  ></audio>
                ) : i.key === 'keys' || i.key === 'notifyList' ? (
                  <div>
                    {i.value.map((k: any, inx: any) => (
                      <div className='text-sm' key={inx}>
                        {k.type} - {k.value === true ? 'да' : 'нет'}
                      </div>
                    ))}
                  </div>
                ) : (
                  <span className='text-sm'>{i.value || '-'}</span>
                )
              }
            </div >
        ));
      } else {
        return <span className='text-sm'>{info}</span>;
      }
    };

    return (
      <div
        className='card cursor card-event h-100'
        onClick={() => {
          setActive(!active);
        }}
      >
        <div className='card-header p-3 pt-2'>
          <div
            className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
            style={{ width: 40, height: 40 }}
          >
            <div className='mt-1'>
              <BsClipboardData color='#ffffff' size={25} />
            </div>
          </div>
          {/* <div className='text-end ms-5 text-sm text-white'>
            {item.event || 'Событие'}
          </div> */}
        </div>
        <div className='card-body mt-0 p-3 pb-0 mb-0 d-flex  justify-content-between flex-wrap'>
          <div className='d-flex flex-column align-items-center justify-content-between flex-wrap mx-2'>
            <span className='text-sm text-white'>Дата и время</span>
            <span className='text-sm'>
              {new Date(item.create_at!).toLocaleString('ru-RU')}
            </span>
          </div>
          <div className='d-flex flex-column align-items-center justify-content-between flex-wrap mx-2'>
            <span className='text-sm text-white'>Событие</span>
            <span className='text-sm'>
              {item.event}
            </span>
          </div>
          <div className='d-flex flex-column align-items-center justify-content-between flex-wrap mx-2'>
            <span className='text-sm text-white'>Объект</span>
            <span className='text-sm'>
              {object.find((i) => i.id == item.object_id)?.name || item.object_id}
            </span>
          </div>


          <div className='d-flex flex-column align-items-center justify-content-between flex-wrap mx-2'>
            <span className='text-sm text-white'>Тема</span>
            <span className='text-sm'>{item.info_subject}</span>
          </div>
          <div className='d-flex flex-column align-items-center justify-content-between flex-wrap mx-2'>
            <span className='text-sm text-white'>ФиО охранника</span>
            <span className='text-sm'>
              {employees.find((i) => i.id == item.guard_id)?.name ||
                item.guard_id}
            </span>
          </div>

          {/* <div className='d-flex flex-column align-items-center justify-content-between flex-wrap mx-3'>
            <span className='text-sm text-white'>Информация: </span>
          </div> */}
          {active && getInfo(item.info!)}
        </div>
        <div className='card-footer mt-0 px-3 pt-0'>
          {active && (
            <div className='mt-1' onClick={(e) => e.stopPropagation()}>
              {item.photo && JSON.parse(item.photo)?.filter((photo: string) => photo).length > 0 ? (
                <div className='d-flex align-items-center hideScroll overflowX image-container'>
                  {JSON.parse(item.photo).filter((photo: string) => photo).map((photo: any, index: number) => (
                    <Zoom>
                      <img
                        key={index}
                        src={`${SERVER_URL}/get-file/${photo}`}
                        alt='preview'
                        className='w-100 card-image'
                      />
                    </Zoom>
                  ))}
                </div>
              ) : (
                <Zoom>
                  <img src={placeholder} alt='preview' className='w-100' />
                </Zoom>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }




};

export default CardEvent;