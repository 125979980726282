import React, { useEffect, useState } from 'react';
import { BsClipboardData, BsMap } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import CardEvent from '../../components/CardEvent/CardEvent';
import Maps from '../../components/Maps/Maps';
import Modal from '../../components/Modal/Modal';
import ModalEmployee from '../../components/ModalEmployee/ModalEmployee';
import Sidebar from '../../components/Sidebar/Sidebar';
import { SERVER_URL } from '../../helpers/const';
import { roles } from '../../navigation/navigation';
import {
  getCheckings,
  ICheckingConf,
} from '../../store/slices/checkingSlice/checkingSlice';
import { getEmployee, getEmployees } from '../../store/slices/employeeSlice/employeeSlice';
import { getEvents } from '../../store/slices/eventSlice/eventSlice';
import {
  getObject,
  getObjects,
} from '../../store/slices/objectSlice/objectSlice';
import { getShifts } from '../../store/slices/shiftSlice/shiftSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import {getCompany} from "../../store/slices/companySlice/companySlice";
import {CompanyAPI} from "../../api/Company/Company";
import {FinanceAPI} from "../../api/Finance/Finance";
import {getFinanceList} from "../../store/slices/financeSlice/financeSlice";
import CardFinance from "../../components/CardFinance/CardFinance";
import {getAllUsers, getUsers, IUserConf} from "../../store/slices/userSlice/userSlice";
import CardObject from "../../components/CardObject/CardObject";
import ModalObject from "../../components/ModalObject/ModalObject";
import CardEmployee from "../../components/CardEmployee/CardEmployee";
import CardUser from "../../components/CardUser/CardUser";
import ModalUser from "../../components/ModalUser/ModalUser";

const CompanyViewPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const employee = useAppSelector((state) => state.employee.currentEmployee);
  const company = useAppSelector((state) => state.company.currentCompany);
  const finance = useAppSelector((state) => state.finance.currentFinance);
  const st = useAppSelector((state) => state);
  useEffect(() => {
    console.log(st);

  }, [st]);
  useEffect(() => {
    CompanyAPI.get(id!).then(res=>console.log(res))

  }, []);

  const checking = useAppSelector((state) => state.checking.data);
  const shift = useAppSelector((state) => state.shift.data);
  const user = useAppSelector((state) => state.user.user);
  const events = useAppSelector((state) => state.event.data);
  const [isClosedAccess, setIsClosedAccess] = useState(!!company?.access || false);

  const employees = useAppSelector((state) => state.employee.data);
  const finances = useAppSelector((state) => state.finance.data);
  const objects = useAppSelector((state) => state.object.data);
  const users = useAppSelector((state) => state.user.data);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [currentItem, setCurrentItem] = useState<IUserConf | null>(null);
  useEffect(() => {
    console.log(company);
    setIsClosedAccess(!!company?.access)
  }, [company]);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const openModal2 = (item: IUserConf) => {
    setCurrentItem(item);
    setOpen2(true);
  };
  const closeModal2 = () => setOpen2(false);




  useEffect(() => {
    if (!user) return;
    if (user.role === roles.admin) {

        dispatch(getFinanceList({ limit: 100, offset: 0, query_and: [{ input: 'company_id', value: Number(id!) }]}));
        dispatch(getObjects({ limit: 100, offset: 0, query_and: [{ input: 'company_id', value: Number(id!) }]}));
        dispatch(getEmployees({ limit: 100, offset: 0, query_and: [{ input: 'company_id', value: Number(id!) }]}));
        dispatch(getUsers({ limit: 100, offset: 0, query_and: [{ input: 'company_id', value: Number(id!) }]}));
        dispatch(getCompany(id!));

    }
  }, [user]);


  const access = [roles.admin].includes(user.role);


  console.log(finance);
  return (
    <div>
      <Sidebar />
      <div className='avoidSidebar py-3 pe-3 ps-sm-3'>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <h6 className='m-0'>Информация</h6>
          {
            access ? (<button className={` btn-sm m-0
            ${isClosedAccess ? "paymentStatusRedBtn":"paymentStatusGreenBtn"}
            `} onClick={()=>{
              console.log(company?.access);
              CompanyAPI.blockAccess({id:Number(id), access:!isClosedAccess})
             //dispatch(getCompany(id!)).then(res=>console.log(res.payload.data.access));
              setIsClosedAccess(!isClosedAccess)
            }}>
                  {isClosedAccess ? "Закрыть доступ!":"Разблокировать!"}

            </button>) : ''
          }
          {
            access ? (<button className='btn btn-info btn-sm m-0 ' onClick={openModal}>
              Изменить
            </button>) : ''
          }
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='card cursor mb-3'>
              <div className='card-header p-3 pt-2'>
                {
                  employee?.photo ? (<div className="border-radius-xl mt-n3" style={{
                    width: 80,
                    height: 80,
                    backgroundImage: `url(${SERVER_URL}/get-file/${employee?.photo})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                  }}></div>) : (
                      <div
                          className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-1'
                          style={{width: 40, height: 40}}
                      >
                        <div className='mt-1'><BsClipboardData color='#ffffff' size={25}/>
                        </div>

                      </div>
                  )
                }

                <div className='text-end ms-5'></div>
              </div>
              <div className='card-body mt-0 p-3'>
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Название</span>
                      <span className='text-sm'>{company?.name}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Баланс</span>
                      <span className='text-sm'>{company?.balance}</span>
                    </div>
                    <div className='d-flex py-2 align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Доступ</span>
                      <span className={`text-sm py-1 ${isClosedAccess ? "paymentStatusGreenBtn" : "paymentStatusRedBtn"}`}>{isClosedAccess ? "Доступ есть" : "Доступ заблокирован"}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Объект</span>
                      <span className='text-sm'>{company?.object}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Пользователи</span>
                      <span className='text-sm'>{company?.users}</span>
                    </div>
                    {
                      access ? (<div className='d-flex align-items-center justify-content-between flex-wrap'>
                        <span className='text-sm text-white'>Люди</span>
                        <span className='text-sm'>{company?.people}</span>
                      </div>) : ''
                    }
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <p className='mb-0'>Финансы</p>
        <div className='d-flex align-items-center myScrollX overflowX pt-3 gap-3'>
          {finances?.map((item: any) => (
            <div className='scrollCard'>
            <CardFinance item={item}/>
            </div>
          ))}
        </div>
        <p className='mb-0'>Объекты</p>
        <div className='d-flex align-items-stretch myScrollX overflowX pt-3 gap-3 mb-3'>
          {objects?.map((item: any) => {
            console.log(item);
            return <CardObject item={item} handleOpen={() => {
            }}/>
          })}
        </div>
        <p className='mb-0'>Пользователи</p>
        <div className='d-flex align-items-stretch myScrollX  overflowX pt-3 gap-3 mb-3 '>
          {users?.map((item: any) => {
            console.log(item);
            return <CardUser mode="inCompany" item={item} handleOpen={openModal2}/>
          })}
        </div>
        <p className='mb-0'>Охраники</p>
        <div className='d-flex align-items-stretch myScrollX overflowX pt-3 gap-3 mb-3'>

          {employees?.map((item: any) => {
            console.log(item);
            return (

                <CardEmployee item={item} handleOpen={() => {}}/>

            )
          })}

        </div>

      </div>
      <Modal
          open={open}
          closeModal={closeModal}
          children={
            <ModalEmployee mode='edit' handleClose={closeModal} item={employee}/>
          }
          width={40}
          translateX={70}
      />
      {/*<Modal*/}
      {/*  open={open2}*/}
      {/*  closeModal={closeModal2}*/}
      {/*  children={*/}
      {/*    <div>*/}
      {/*      <Maps coords={coords} />*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*/>*/}
      <Modal
          open={open2}
          closeModal={closeModal2}
          children={
            <ModalUser
                mode='edit'
                handleClose={closeModal2}
                item={currentItem!}
            />
          }
          width={40}
          translateX={70}
      />

    </div >
  );
};

export default CompanyViewPage;
