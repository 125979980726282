import React, { SyntheticEvent, useEffect, useState } from 'react';
import { roles } from '../../navigation/navigation';
import {
  createObject,
  IObjectConf,
  updateObject
} from '../../store/slices/objectSlice/objectSlice';
import { IUserConf } from '../../store/slices/userSlice/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import AddAddressesForm from '../AddAdressesForm/AddAddressesForm';
import AddKeysForm from '../AddKeysForm/AddKeysForm';
import Input from '../Input/Input';
import Select from '../Select/Select';
import WorkScheduleForm from '../WorkScheduleForm/WorkScheduleForm';

interface IModalObject {
  mode: 'edit' | 'create' | 'view';
  handleClose: () => void;
  createFinance:(data:{summa:number})=>void;
}

const ModalFinance: React.FC<IModalObject> = ({ mode, createFinance, handleClose }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const employees = useAppSelector((state) => state.employee.data);
  const users = useAppSelector((state) => state.user.data);
  const adminEmployees = users.filter(emp => emp.role === roles.headOfSecurity)


  const [summa, setSum] = useState("");


  const handleSumhange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSum(e.currentTarget.value);
  };

  const handleSubmit = () => {
    if (mode === 'create') {
        console.log(summa);
      createFinance({summa:Number(summa)});
    }
    handleClose();
  };



  return (
    <div className='px-1'>
      <h6>
        {mode === 'create'
          ? 'Добавить'
          : mode === 'edit'
            ? 'Редактировать'
            : 'Просмотр'}
      </h6>
      <div className='mb-2'>
        {/* <p className='mb-1 text-sm'>Наименование</p> */}
        <div>
          <Input
            type="number"
            value={summa}
            handleChange={handleSumhange}
            placeholder='Сумма'
          />
        </div>
      </div>

      <button className='btn btn-info w-100' onClick={handleSubmit}>
        Cохранить
      </button>
    </div>
  );
};

export default ModalFinance;
