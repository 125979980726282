import { EventAPI } from './../../../api/Event/EventAPI';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export interface EventState {
  data: IEventConf[];
  total: number;
  isRequested: boolean;
}

export interface IEventConf {
  id?: number;
  create_at?: string;
  event?: string;
  guard_id?: number;
  info?: string;
  info_subject?: string;
  object_id?: number;
  photo?: string;
  company_id?: number;
}

interface IPagination {
  limit: number;
  offset: number;
  query_and?: any;
  query?: any;
}

const initialState: EventState = {
  data: [],
  total: 0,
  isRequested: false
};

export const getEvents = createAsyncThunk(
  'event/getEvents',
  async ({ limit, offset, query_and,query }: IPagination, thunkAPI) => {
    const response = await EventAPI.getList(limit, offset, query_and,query);
    return response;
  }
);

export const createEvent = createAsyncThunk(
  'event/createEvent',
  async (data: IEventConf, thunkAPI) => {
    const response = await EventAPI.create(data);
    return response;
  }
);

export const updateEvent = createAsyncThunk(
  'event/updateEvent',
  async (data: IEventConf, thunkAPI) => {
    const response = await EventAPI.update(data);
    return response;
  }
);

export const deleteEvent = createAsyncThunk(
  'event/deleteEvent',
  async (data: IEventConf, thunkAPI) => {
    const response = await EventAPI.delete(data);
    return { ...response, item: data };
  }
);

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    resetEventsRequested(state){
      state.isRequested = false;
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getEvents.fulfilled, (state, action) => {
      if (action.payload.status && action.payload.data) {
        state.data = action.payload.data;
        state.total = action.payload.count;
        state.isRequested = true;
      }
    });
    builder.addCase(createEvent.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data.push(action.payload.event);
      }
    });
    builder.addCase(updateEvent.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data = state.data.map((item) =>
          item.id == action.payload.event.id ? action.payload.event : item
        );
      }
    });
    builder.addCase(deleteEvent.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data = state.data.filter((i) => i.id != action.payload.item.id);
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {resetEventsRequested} = eventSlice.actions;

export default eventSlice.reducer;
