import React, { useRef } from 'react';
import { Map, Polyline, Placemark } from 'react-yandex-maps';

//у библиотеки некорретная типизация пропсов, могут быть проблемы при tsx
const Maps = ({ coords }) => {
  const map = useRef(null);
  const PreParedData = coords.map((item) => [item.latitude, item.longitude]);
  const center = PreParedData[0];

  return (
    <Map
      defaultState={{
        center: center ? center : [55.018803, 82.933952],
        zoom: center ? 18 : 11,
      }}
      height='60vh'
      width='80vw'
      instanceRef={map}
      style={{ height: '100vh', width: '100vw', marginTop: 30 }}
    >
      <Placemark
        geometry={PreParedData[0]}
        options={{
          preset: 'islands#redStretchyIcon',
          // Disabling the close balloon button.
          balloonCloseButton: false,
          // The balloon will open and close when the placemark icon is clicked.
          hideIconOnBalloonOpen: false,
        }}
        properties={{
          iconContent: 'Начало',
          balloonContent: 'Начало пути',
        }}
      />
      <Polyline
        geometry={PreParedData}
        options={{
          balloonCloseButton: true,
          strokeColor: '#000',
          strokeWidth: 3,
          strokeOpacity: 0.6,
          editorMaxPoints: 6,
          // Adding a new item to the context menu that allows deleting the polyline.
          editorMenuManager: (PreParedData) => {
            PreParedData.push({
              title: 'Delete line',
              onClick: () => {},
            });
            return PreParedData;
          },
        }}
      />
      <Placemark
        geometry={PreParedData[PreParedData.length - 1]}
        options={{
          preset: 'islands#blueStretchyIcon',
          // Disabling the close balloon button.
          balloonCloseButton: false,
          // The balloon will open and close when the placemark icon is clicked.
          hideIconOnBalloonOpen: false,
        }}
        properties={{
          iconContent: 'Конец',
          balloonContent: 'Конец пути',
        }}
      />
    </Map>
  );
};

export default Maps;
