import React, {useEffect, useState} from 'react';
import CardEvent from '../../components/CardEvent/CardEvent';
import Pagination from '../../components/Pagination/Pagination';
import Sidebar from '../../components/Sidebar/Sidebar';
import {roles} from '../../navigation/navigation';
import {getEmployees} from '../../store/slices/employeeSlice/employeeSlice';
import {
  getEvents,
  IEventConf,
} from '../../store/slices/eventSlice/eventSlice';
import {getObjects} from '../../store/slices/objectSlice/objectSlice';
import {useAppDispatch, useAppSelector} from '../../store/store';
import {SERVER_URL} from "../../helpers/const";
import {EventRow} from "../../components/EventRow/EventRow";

const EventPage = () => {
  const [offset, setOffset] = useState(0);
  const dispatch = useAppDispatch();
  const total = useAppSelector((state) => state.event.total);
  const data = useAppSelector((state) => state.event.data);
  const user = useAppSelector((state) => state.user.user);
  const objects = useAppSelector((state) => state.object.data);
  const objectsRequested = useAppSelector((state) => state.object.isRequested);
  const employeesRequested = useAppSelector((state) => state.employee.isRequested);
  const eventsRequested = useAppSelector((state) => state.event.isRequested);

  useEffect(() => {
    if (!user) return;
    if (user.role === roles.client && objectsRequested) {
      const query = objects.map(object => ({input: 'object_id', value: object.id}))

      dispatch(getEvents({limit: 20, offset, query}))

      // const securityIds: any = objects.map(object => {
      //   return JSON.parse(object.ohraniki).map((security: any) => security.value)
      // });
      // dispatch(getEmployees({ limit: 0, offset: 0, query: securityIds.flat().map((id: any) => ({ input: 'id', value: id })) }));

    } else if (user.role !== roles.client) {
      dispatch(getEvents({limit: 20, offset}));
    }
  }, [offset]);


  useEffect(() => {
    if (!user) return;
    if (user.role === roles.client) {
      if (!objectsRequested) {
        dispatch(getObjects({limit: 0, offset: 0, query_and: [{input: 'client_id', value: user.id}]}));

      } else if (!eventsRequested) {
        const query = objects.map(object => ({input: 'object_id', value: object.id}))

        dispatch(getEvents({limit: 20, offset: 0, query}))
      } else if (!employeesRequested) {

        const securityIds: any = objects.map(object => {
          return JSON.parse(object.ohraniki).map((security: any) => security.value)
        });
        dispatch(getEmployees({
          limit: 0,
          offset: 0,
          query: securityIds.flat().map((id: any) => ({input: 'id', value: id}))
        }));
      }

    } else if (!objectsRequested) {
      dispatch(getObjects({limit: 0, offset: 0}));
      dispatch(getEmployees({limit: 0, offset: 0}));
    }
  }, [data, user, objectsRequested, eventsRequested]);

  return (
    <div>
      <Sidebar/>
      <div className='avoidSidebar py-3 pe-3 ps-sm-3'>
        <div className="card  mb-4">
          <div className="table-responsive">
            <table className="table align-items-center mb-0">
              <thead>
              <tr>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Название</th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Охраник</th>
                <th
                  className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Дата и
                  время
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Тема
                </th>
              </tr>
              </thead>
              <tbody>
              {data.map((item: IEventConf, index) => (
                <EventRow item={item}></EventRow>
              ))}

              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          offset={offset}
          total={total}
          handleBack={() => {
            if (offset <= 0) return;
            setOffset(offset - 10);
          }}
          handleNext={() => {
            if (offset > total) return;
            setOffset(offset + 10);
          }}
        />
      </div>
    </div>
  );
};

export default EventPage;
