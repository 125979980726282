import React from 'react';
import { IconBaseProps } from 'react-icons'

interface ICardReport {
  header: string,
  count: string,
  mode?:string,
  Img: React.ElementType<IconBaseProps>
}

const CardReport: React.FC<ICardReport> = ({ header, count, Img ,mode}) => {

  return (
    <div className='cardReport'>
      <div className='card'>
        <div className='card-header m-auto p-3 text-center row'>
          <div
            className='m-auto d-flex justify-content-center'
            style={{ width: 40, height: 40 }}
          >

            <div className='mt-1'>
              <div className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl d-flex align-items-center justify-content-center'
                style={{ width: 40, height: 40 }}>

                <Img color='#ffffff' size={25} />

              </div>
            </div>

          </div>
        </div>
        <div className='card-body pt-0 p-3 text-center'>
          <h6 className='text-center mb-0'>{header}</h6>
          <span className='text-xs'></span>
          <hr
            className={`horizontal light my-3`}
          />
          <h5 className='mb-0'>{count}{ mode && " "+mode}</h5>
        </div>
      </div>
    </div>
  );
};

export default CardReport;
