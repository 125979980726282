import { Navigate } from 'react-router-dom';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import { useAppSelector } from '../../store/store';

interface IPrivateRoute {
  children: JSX.Element;
  roles: Array<string>
}

const PrivateRoute: React.FC<IPrivateRoute> = ({ children, roles, ...rest }) => {

  const token = useAppSelector((state) => state.user.token);
  const loading = useAppSelector((state) => state.user.loading);
  const user = useAppSelector((state) => state.user.user);
  console.log(token,loading, user);
  console.log(roles);
  if (loading) {
    return <LoadingPage />;
  }
  // return <>fkjdlfjkd</>
  return token ? ((roles.includes(user.role)) ? children : <Navigate to='/' replace={true} />) : <Navigate to='/auth/signin' replace={true} />;

};

export default PrivateRoute;
