import React, {useEffect, useState} from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import CardEmployee from "../../components/CardEmployee/CardEmployee";
import {roles} from "../../navigation/navigation";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {UserAPI} from "../../api/User/User";
import {FinanceAPI} from "../../api/Finance/Finance";
import Pagination from "../../components/Pagination/Pagination";
import CardCompany from "../../components/CardCompany/CardCompany";
import {getUsers} from "../../store/slices/userSlice/userSlice";
import ModalObject from "../../components/ModalObject/ModalObject";
import Modal from "../../components/Modal/Modal";
import ModalFinance from "../../components/ModalFinance/ModalFinance";
import CardFinance from "../../components/CardFinance/CardFinance";
import {CompanyAPI} from "../../api/Company/Company";
import {getCompanyList} from "../../store/slices/companySlice/companySlice";

const Finances = () => {
        const user = useAppSelector((state) => state.user.user);
        const company = useAppSelector(state=>state.company.data);
        const [offset, setOffset] = useState(0);
        //const total = useAppSelector((state) => state.user.total);
        const [total, setTotal] = useState(0);
        const [finances, setFinances] = useState<any[]>([]);
        const [open, setOpen] = useState(false);
        const access = [roles.admin].includes(user?.role) ;
        const dispatch = useAppDispatch();
        const openModal = () => setOpen(true);
        const closeModal = () => setOpen(false);
        useEffect(() => {
            console.log(total);
            FinanceAPI.getList(10, offset)
                .then(res => {
                    console.log(res);
                    setFinances(res.data)
                    setTotal(res.count)
                })
            dispatch(getCompanyList({ limit: 10, offset: 0}))

        }, [])


        useEffect(() => {
            FinanceAPI.getList(10, offset).then(res => setFinances(res.data))
        }, [offset]);
        const createFinance = (data: { summa: number }) => {
            FinanceAPI.create(data).then(res => {
                setFinances(prev => [...prev, res.finance]);
                window.location.href = res.finance.confirmation_url;
            });
        }
        return (
            <div>
                <Sidebar/>
                <div className='avoidSidebar py-3 pe-3 ps-sm-3'>
                    <div className='d-flex align-items-center justify-content-between mb-4'>
                        <button className='btn btn-info btn-sm m-0' onClick={openModal}>
                            Добавить
                        </button>
                    </div>
                    <div className='row'>
                        {
                            finances.map((finance, index) =>
                                <div className='col-12 col-md-4' key={index}>
                                    <CardFinance access={access} companyName={company.find(comp=>comp.id===finance.company_id)?.name || ""}  item={finance}/>
                                </div>
                            )
                        }
                    </div>

                </div>
                <Pagination
                    offset={offset}
                    total={total}
                    handleBack={() => {
                        if (offset <= 0) return;
                        setOffset(offset - 10);
                    }}
                    handleNext={() => {
                        if (offset > total) return;
                        setOffset(offset + 10);
                    }}
                    handleChose={(page)=>{
                        setOffset(page);
                    }}
                />
                <Modal
                    open={open}
                    closeModal={closeModal}
                    children={<ModalFinance createFinance={createFinance} mode='create' handleClose={closeModal}/>}
                    width={20}
                    height={40}
                    translateX={200}
                    translateY={20}
                    isFinance={true}
                />
            </div>
        );
    }
;

export default Finances;
