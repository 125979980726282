import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const usePopup = () => {
    function popup(yesCb:any) {
        console.log(typeof yesCb);
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="text-center w-96 p-5 bg-white text-black shadow-lg ">
                        <h1 className="mt-0 text-2xl">Подтверждение операции</h1>
                        <p className="text-sm">
                            Вы уверены, что хотите провести данную операцию?
                        </p>
                        <div className="flex gap-3 mt-4">
                            {/* <button className=" w-40 p-2 border border-white bg-none text-white text-sm" onClick={() => { noCb(); onClose(); }}>Нет</button> */}
                            <button
                                className=" yesNoBtn"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Нет
                            </button>
                            <button
                                className="yesNoBtn"
                                onClick={() => {
                                    yesCb();
                                    onClose();
                                }}
                            >
                                Да!
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }
    return popup;
};

export default usePopup;
