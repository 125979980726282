import { SERVER_URL } from './../../helpers/const';
interface IFinance {
  id?: number;
  summa:number;
}

export const FinanceAPI = {

  get: async (id: string | number) => {
    const response = await fetch(`${SERVER_URL}/company/get/${id}`, {
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  create: async (body: IFinance) => {
    const response = await fetch(`${SERVER_URL}/finance/create`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  changeStatus: async (id:number)=>{
    const response = await fetch(`${SERVER_URL}/finance/add/${id}`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  getList: async (
      limit: number = 0,
      offset: number = 0,
      query_and: any = null,
      query: any = null
  ) => {
    let body = JSON.stringify({ limit, offset });

    if (query && query_and) {
      body = JSON.stringify({ limit, offset, query_and, query });
    } else if (query_and) {
      body = JSON.stringify({ limit, offset, query_and });
    } else if (query) {
      body = JSON.stringify({ limit, offset, query });
    }
    const response = await fetch(`${SERVER_URL}/finance/get-list`, {
      method: 'POST',
      body: body,
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
};
