import { SERVER_URL } from './../../helpers/const';
export interface ICompany {
  id: number,
  access?: boolean,
  api_key?: string,
  bank_registration_date?: string,
  is_super_company?: boolean,
  last_activity_datetime?: string,
  balance?: number,
  balance_ref?: number,
  name?: string,
  type?: string,
  object?: number,
  users?: number,
  people?: number
}

export const CompanyAPI = {

  get: async (id: string | number) => {
    const response = await fetch(`${SERVER_URL}/company/get/${id}`, {
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  delete: async (body: ICompany) => {
    const response = await fetch(`${SERVER_URL}/company/delete`, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },

  update: async (body: ICompany) => {
    const response = await fetch(`${SERVER_URL}/company/update`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  blockAccess: async ({id, access}:ICompany)=>{
    const response = await fetch(`${SERVER_URL}/company/update`, {
      method: 'PUT',
      body: JSON.stringify({id, access}),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;

  },
  getList: async (limit: number = 0, offset: number = 0, query_and: any = null) => {

    const response = await fetch(`${SERVER_URL}/company/get-list`, {
      method: 'POST',
      body: JSON.stringify({ limit, offset, query_and }),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
};
