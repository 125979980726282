import { UserAPI } from './../../../api/User/User';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export interface UserState {
  token: string | null;
  user: any;
  allUsers: IUserConf[];
  loading: boolean;
  data: IUserConf[];
  total: number;
  currentUser?: IUserConf;
  error?: string;
}

interface IPagination {
  limit: number;
  offset: number;
  query_and?: any;
}

export interface IUserConf {
  id?: number;
  password?: string;
  password_hash?:string;
  avatar_url?: string;
  company_id?: number;
  phone?: string;
  push_token?: string;
  role?: string;
  access?: boolean;
  login?: string;
  role_id?: number;
  name?: string;
  ref_id?: number;
  code?:string | undefined;
}

const initialState: UserState = {
  token: null,
  user: null,
  allUsers:[],
  loading: true,
  total: 0,
  data: [],
  currentUser: undefined,
  error: undefined,
};

export const registerUser = createAsyncThunk(
  'user/register',
  async ({ login, password, role, name }: IUserConf, thunkAPI) => {
    const response = await UserAPI.register(login!, password! ,role!, name! );
    return response;
  }
);

export const createUser = createAsyncThunk(
  'user/createUser',
  async (newUser: IUserConf, thunkAPI) => {
    const response = await UserAPI.create(newUser);
    return response;
  }
);

export const loginUser = createAsyncThunk(
  'user/login',
  async ({ login, password, code }: IUserConf, thunkAPI) => {
    const response = await UserAPI.login(login!, password!, code);
    return response;
  }
);

export const getUser = createAsyncThunk(
  'user/getUser',
  async (id: string | number, thunkAPI) => {
    const response = await UserAPI.get(id);
    return response;
  }
);

export const getUserInfo = createAsyncThunk(
  'user/getUserInfo',
  async (id: string | number, thunkAPI) => {
    const response = await UserAPI.get(id);
    return response;
  }
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (data: IUserConf, thunkAPI) => {
    const response = await UserAPI.update(data);
    return response;
  }
);

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async (data: IUserConf, thunkAPI) => {
    const response = await UserAPI.delete(data);
    return { ...response, item: data };
  }
);

export const getUsers = createAsyncThunk(
  'user/getUsers',
  async ({ limit, offset, query_and }: IPagination, thunkAPI) => {
    const response = await UserAPI.getList(limit, offset, query_and);
    console.log(response);
    return response;
  }
);
export const getAllUsers = createAsyncThunk(
    'user/getAllUsers',
    async ({ limit, offset, query_and }: IPagination, thunkAPI) => {
      const response = await UserAPI.getList(limit, offset, query_and);
      console.log(response);
      return response;
    }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    logout: (state) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(registerUser.fulfilled, (state, action) => {
      console.log('register')
      if (action.payload.status) {
        state.token = action.payload.token;
        state.user = action.payload.user;
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('userId', action.payload.user.id);
      } else {
        if (action.payload.message === 'пользователь не найден') {
          state.error = 'Пользователь не найден';
        } else if (action.payload.message === 'Не верный пароль') {
          state.error = 'Неверный пароль';
        } else {
          state.error = action.payload.message;
        }
      }
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      console.log('createUser')
      state.data.push(action.payload.user)
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      console.log('loginuser')

      if(action.payload.message === "Вам выслали код авторизации") {
        state.error = action.payload.message;
        return;
      }
      if (action.payload.status) {
        state.token = action.payload.token;
        state.user = action.payload.user;
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('userId', action.payload.user.id);
      } else {
        if (action.payload.message === 'пользователь не найден') {
          state.error = 'Пользователь не найден';
        } else if (action.payload.message === 'Не верный пароль') {
          state.error = 'Неверный пароль';
        } else {
          state.error = action.payload.message;
        }
      }
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.user = action.payload.data;
        state.token = localStorage.getItem('token');
      }
      state.loading = false;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.currentUser = action.payload.data;
      }
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data = state.data.map((item) =>
          item.id == action.payload.user.id ? action.payload.user : item
        );
        if (state.currentUser && state.currentUser.id === action.payload.user.id) {
          state.currentUser = action.payload.user;
        }

      }
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data = state.data.filter((i) => i.id != action.payload.item.id);
      }
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload.status && action.payload.data.length > 0) {
        state.data = action.payload.data;
        state.total = action.payload.count;
      }
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      if (action.payload.status && action.payload.data.length > 0) {
        state.allUsers = action.payload.data;

      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { startLoading, stopLoading, logout, setError } =
  userSlice.actions;

export default userSlice.reducer;
