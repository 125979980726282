import React, { useState } from 'react';
import { MdAdd, MdDelete } from 'react-icons/md';
import Input from '../Input/Input';
import Select from '../Select/Select';

interface IAddKeysForm {
  items: any;
  handleChange: (i: any) => void;
}

const AddKeysForm: React.FC<IAddKeysForm> = ({ items, handleChange }) => {
  const [room, setRoom] = useState('');
  const options = [
    { label: 'Склад', value: 'Склад' },
    { label: 'Комната', value: 'Комната' },
  ];

  const handleKeyAdd = () => {
    handleChange([...items, { type: room, value: false }]);
    setRoom('');
  };

  const handleKeyRemove = (type: string) => {
    handleChange(items.filter((k: any) => k.type !== type));
  };

  return (
    <div>
      <p className='mb-1 text-sm'>Наборы ключей</p>
      <div className='d-flex align-items-center mb-1'>
        <div className='w-100'>
          <Input
            value={room}
            handleChange={(e: any) => {
              setRoom(e.currentTarget.value);
            }}
            placeholder='Введите'
          />
        </div>
        <div className='text-info cursor ms-2'>
          <MdAdd size={25} onClick={handleKeyAdd} />
        </div>
      </div>
      {items.map((k: any, index: number) => (
        <div
          key={index}
          className='d-flex align-items-center justify-content-between mb-1'
        >
          <div className='text-sm'>{k.type}</div>
          <div className='text-info cursor'>
            <MdDelete size={20} onClick={() => handleKeyRemove(k.type)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddKeysForm;
