import React, { useEffect, useState } from 'react';
import CardShift from '../../components/CardShift/CardShift';
import Pagination from '../../components/Pagination/Pagination';
import Sidebar from '../../components/Sidebar/Sidebar';
import { roles } from '../../navigation/navigation';
import { getEmployees } from '../../store/slices/employeeSlice/employeeSlice';
import { getObjects } from '../../store/slices/objectSlice/objectSlice';
import {
  getShifts,
  IShiftConf,
} from '../../store/slices/shiftSlice/shiftSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';

const ShiftPage = () => {
  const dispatch = useAppDispatch();
  const [offset, setOffset] = useState(0);
  const data = useAppSelector((state) => state.shift.data);
  const total = useAppSelector((state) => state.shift.total);
  const dataRequested = useAppSelector((state) => state.shift.isRequested);
  const objectsRequested = useAppSelector((state) => state.object.isRequested);
  const objects = useAppSelector((state) => state.object.data);
  const employeesRequested = useAppSelector((state) => state.employee.isRequested);
  const user = useAppSelector((state) => state.user.user);



  useEffect(() => {

    if (user.role === roles.client && objectsRequested) {
      dispatch(getShifts({ limit: 20, offset, query: objects.map(object => ({ input: 'object_id', value: object.id })) }));
      // const securityIds: any = objects.map(object => {
      //   return JSON.parse(object.ohraniki).map((security: any) => security.value)
      // });
      // dispatch(getEmployees({ limit: 0, offset: 0, query: securityIds.flat().map((id: any) => ({ input: 'id', value: id })) }));
    } else if (user.role !== roles.client) {
      dispatch(getShifts({ limit: 20, offset }));
      // dispatch(getEmployees({ limit: 20, offset}));
    }

  }, [offset]);

  useEffect(() => {
    if (!user) return;
    if (user.role === roles.client) {
      if (!objectsRequested) {
        dispatch(getObjects({ limit: 0, offset: 0, query_and: [{ input: 'client_id', value: user.id }] }));
      } else if (!dataRequested) {
        dispatch(getShifts({ limit: 20, offset: 0, query: objects.map(object => ({ input: 'object_id', value: object.id })) }));

        const securityIds: any = objects.map(object => {
          return JSON.parse(object.ohraniki).map((security: any) => security.value)
        });

        dispatch(getEmployees({ limit: 0, offset: 0, query: securityIds.flat().map((id: any) => ({ input: 'id', value: id })) }));
      }

    } else if (!dataRequested) {
      dispatch(getObjects({ limit: 0, offset: 0 }));
      dispatch(getEmployees({ limit: 0, offset: 0 }));
    }
  }, [data, user, objects, dataRequested, objectsRequested,]);


  return (
    <div>
      <Sidebar />
      <div className='avoidSidebar py-3 pe-3 ps-sm-3'>
        <div className='d-flex align-items-center justify-content-between mb-4'></div>
        <div className='row'>
          {data.map((item: IShiftConf, index) => (
            <div className='col-12 col-md-4' key={index}>
              <CardShift item={item} />
            </div>
          ))}
        </div>
        <Pagination
          offset={offset}
          total={total}
          handleBack={() => {
            if (offset <= 0) return;
            setOffset(offset - 10);
          }}
          handleNext={() => {
            if (offset > total) return;
            setOffset(offset + 10);
          }}
          handleChose={(page)=>{
            setOffset(page);
          }}
        />
      </div>
    </div>
  );
};

export default ShiftPage;
