import React, {SyntheticEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Input from '../../components/Input/Input';
import {loginUser, setError} from '../../store/slices/userSlice/userSlice';
import {useAppDispatch, useAppSelector} from '../../store/store';

const SignInPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState<string | undefined>(undefined);
    const [showCode, setShowCode] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const error = useAppSelector((state) => state.user.error);
    const st = useAppSelector((state) => state);
    useEffect(() => {
        console.log(st);
    }, [st]);

    const handlePasswordChange = (e: SyntheticEvent<HTMLInputElement>) =>
        setPassword(e.currentTarget.value);
    const handleCodeChange = (e: SyntheticEvent<HTMLInputElement>) =>
        setCode(e.currentTarget.value);
    const handleEmailChange = (e: SyntheticEvent<HTMLInputElement>) =>
        setEmail(e.currentTarget.value);
    useEffect(() => {
        if(error === "Вам выслали код авторизации")
            setShowCode(true);
    }, [error]);
    const handleSignIn = (withCode?:boolean) => {
        if (!email || !password) {
            dispatch(setError('Заполните все поля'));
        } else {

            dispatch(setError(undefined));
            if (!withCode)
                dispatch(loginUser({login: email, password: password, code: showCode ? code : undefined}));
            else
                dispatch(loginUser({login: email, password: password}));

        }
    };


    return (
        <div className='fullHeight d-flex align-items-center justify-content-center'>
            <div className='d-flex flex-column align-items-stretch w-60 w-md-20'>
                <h4 className='mb-3 text-center'>Вход</h4>
                <div className='mb-2'>
                    <Input
                        autoComplete
                        placeholder='Email'
                        value={email}
                        handleChange={handleEmailChange}
                    />
                </div>
                <div className='mb-2'>
                    <i className="bi bi-eye-fill"></i>
                    <Input
                        autoComplete
                        placeholder='Пароль'
                        value={password}
                        handleChange={handlePasswordChange}
                        type='password'
                        handleEnter={handleSignIn}
                    />
                </div>
                {
                    showCode &&
                    <div className='mb-2'>
                        <Input
                            autoComplete
                            placeholder='Код подтверждения'
                            value={code ? code : ''}
                            handleChange={handleCodeChange}
                            type='text'
                            handleEnter={handleSignIn}
                        />
                        <div className='text-sm cursor text-info m-2'
                             onClick={() => {
                                 setCode(undefined);
                                 handleSignIn(true);
                             }}
                        >Отправить код повторно?
                        </div>
                    </div>
                }

                {error && <div className='text-sm text-red mb-2'>{error}</div>}
                <button onClick={()=>handleSignIn(false)} className='btn btn-info'>
                    Войти
                </button>
                <p className='text-center'>
                    Нет аккаунта?{' '}
                    <span
                        className='cursor text-info'
                        onClick={() => {
                            dispatch(setError(undefined));
                            navigate('/auth/signup', {replace: true});
                        }}
                    >
                      Создать аккаунт
                    </span>
                </p>
            </div>
        </div>
    );
};

export default SignInPage;
