import React, {SyntheticEvent, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Input from '../../components/Input/Input';
import {registerUser, setError} from '../../store/slices/userSlice/userSlice';
import {useAppDispatch, useAppSelector} from '../../store/store';
import {validationPassword} from "../../utils/validationPassword";

const SignUpPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const error = useAppSelector((state) => state.user.error);

    const handlePasswordChange = (e: SyntheticEvent<HTMLInputElement>) =>
        setPassword(e.currentTarget.value);
    const handleEmailChange = (e: SyntheticEvent<HTMLInputElement>) =>
        setEmail(e.currentTarget.value);
    const handleNameChange = (e: SyntheticEvent<HTMLInputElement>) =>
        setName(e.currentTarget.value)
    const handleSignUp = () => {

        if (!email || !password || !name) {
            dispatch(setError('Заполните все поля'));
            return;
        }
        const validationError = validationPassword(password)
        if (validationError) {
            dispatch(setError(validationError));
            return
        }
        dispatch(setError(undefined));
        debugger
        dispatch(registerUser({login: email, password: password, name: name, role: 'Директор'}));

    };

    return (
        <div className='fullHeight d-flex align-items-center justify-content-center'>
            <div className='d-flex flex-column align-items-stretch w-60 w-md-20'>
                <h4 className='mb-3 text-center'>Регистрация</h4>
                <div className='mb-2'>
                    <Input
                        placeholder='Email'
                        value={email}
                        handleChange={handleEmailChange}
                    />
                </div>
                <div className='mb-2'>
                    <Input
                        placeholder='Название компании'
                        value={name}
                        handleChange={handleNameChange}
                        type='text'

                    />
                </div>
                <div className='mb-2'>
                    <Input
                        placeholder='Пароль'
                        value={password}
                        handleChange={handlePasswordChange}
                        type='password'
                        handleEnter={handleSignUp}
                    />
                </div>

                {error && <div className='text-sm text-red mb-2'>{error}</div>}
                <button onClick={handleSignUp} className='btn btn-info'>
                    Зарегистрироваться
                </button>
                <p className='text-center'>
                    Есть аккаунт?{' '}
                    <span
                        className='cursor text-info'
                        onClick={() => {
                            dispatch(setError(undefined));
                            navigate('/auth/signin', {replace: true});
                        }}
                    >
            Войти
          </span>
                </p>
            </div>
        </div>
    );
};

export default SignUpPage;
