import { SERVER_URL } from './../../helpers/const';
interface IShift {
  id?: number;
  datetime_end?: string;
  datetime_start?: string;
  guard_id?: number;
  object_id?: number;
  company_id?: number;
  coords?: string;
}

export const ShiftAPI = {
  create: async (body: IShift) => {
    const response = await fetch(`${SERVER_URL}/shift/create`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  update: async (body: IShift) => {
    const response = await fetch(`${SERVER_URL}/shift/update`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  delete: async (body: IShift) => {
    const response = await fetch(`${SERVER_URL}/shift/delete`, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  get: async (id: string | number) => {
    const response = await fetch(`${SERVER_URL}/shift/get/${id}`, {
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  getList: async (
    limit: number = 0,
    offset: number = 0,
    query_and: any = null, 
    query: any = null
  ) => {
    let body = JSON.stringify({ limit, offset });

    if (query && query_and) {
      body = JSON.stringify({ limit, offset, query_and, query });
    } else if (query_and) {
      body = JSON.stringify({ limit, offset, query_and });
    } else if (query) {
      body = JSON.stringify({ limit, offset, query });
    }
    const response = await fetch(`${SERVER_URL}/shift/get-list`, {
      method: 'POST',
      body,
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
};
