import React from 'react';
import {Link} from "react-router-dom";
import {slicePage} from "../../utils/slicePages";

interface IPagination {
  handleNext?: () => void;
  handleBack?: () => void;
  handleChose?:(page:number) => void;
  total?: number;
  offset?: number;
}

const Pagination: React.FC<IPagination> = ({
  handleBack,
  handleNext,
  handleChose,
  total,
  offset,
}) => {
    console.log(offset);
    console.log(total);
    let pages:number[] = [];
    for (let i=1;i<Math.ceil(total!/10)+1;i++)
    {
        pages = [...pages,i]
    }
    console.log(slicePage((offset! / 10)+1, pages));
    pages=slicePage((offset! / 10)+1, pages)
    console.log(offset!/10);
  return (
    <div className='d-flex align-items-center justify-content-center gap-3'>
      <button
        className='btn btn-outline-info btn-sm mb-0'
        onClick={handleBack}
        disabled={offset! - 10 < 0}
      >
        Назад
      </button>

        <div className="d-flex gap-2 align-items-center">
            {
                pages.map(page=> {

                  if(page<0)
                      return(
                          <div className="text-white fs-1">
                              ...
                          </div>
                      )
                    else
                      return(
                          <div onClick={() => {
                              if (!handleChose)
                                  return;
                                console.log(page);
                              handleChose((page-1) * 10);

                          }} className={`cursor-pointer page ${(offset! / 10)+1 == page ? 'selected' : ""}`}>
                              {page}
                          </div>
                      )

                })
            }

        </div>
        <button
            className='btn btn-outline-info btn-sm mb-0'
            onClick={handleNext}
            disabled={offset! + 10 >= total!}
        >
            Вперед
        </button>
    </div>
  );
};

export default Pagination;
