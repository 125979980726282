import React, { SyntheticEvent, useEffect, useState } from 'react';
import { roles } from '../../navigation/navigation';
import {
  createObject,
  IObjectConf,
  updateObject
} from '../../store/slices/objectSlice/objectSlice';
import { IUserConf } from '../../store/slices/userSlice/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import AddAddressesForm from '../AddAdressesForm/AddAddressesForm';
import AddKeysForm from '../AddKeysForm/AddKeysForm';
import Input from '../Input/Input';
import Select from '../Select/Select';
import WorkScheduleForm from '../WorkScheduleForm/WorkScheduleForm';

interface IModalObject {
  mode: 'edit' | 'create' | 'view';
  item?: IObjectConf;
  handleClose: () => void;
}

const ModalObject: React.FC<IModalObject> = ({ mode, item, handleClose }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const employees = useAppSelector((state) => state.employee.data);
  const users = useAppSelector((state) => state.user.data);
  const adminEmployees = users.filter(emp => emp.role === roles.headOfSecurity)

  const clients = users.filter(user => user.role === roles.client)
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [keeper, setKeeper] = useState([]);
  const [client, setClient] = useState<IUserConf | null>(null);
  const [boss, setBoss] = useState('');
  const [quantity, setQuantity] = useState('');
  const [duration, setDuration] = useState('');
  const [key, setKey] = useState<any[]>([]);
  const [address, setAddress] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [days, setDays] = useState<any[]>([
    { id: 1, value: '', name: 'Понедельник' },
    { id: 2, value: '', name: 'Вторник' },
    { id: 3, value: '', name: 'Среда' },
    { id: 4, value: '', name: 'Четверг' },
    { id: 5, value: '', name: 'Пятница' },
    { id: 6, value: '', name: 'Суббота' },
    { id: 7, value: '', name: 'Воскресенье' },
  ]);


  const handleNameChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(e.currentTarget.value);
  };
  const handleDescriptionChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDescription(e.currentTarget.value);
  };
  const handleKeeperChange = (value: any) => {
    setKeeper(value);
  };
  const handleClientChange = (value: any) => {
    setClient(value);
  };
  const handleBossChange = (value: any) => {
    setBoss(String(value));
  };
  const handleQuantityChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuantity(e.currentTarget.value);
  };
  const handleDurationChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDuration(e.currentTarget.value);
  };
  const handleKeyChange = (i: any) => {
    setKey(i);
  };
  const handleAddressChange = (i: any) => {
    setAddress(i);
  };
  const handleCreate = (value: any) => {
    setItems([...items, { value, id: (Math.random() / 231) * 22.1 }]);
  };
  const handleDelete = (item: any) => {
    setItems(items.filter((i) => i.id != item.id));
  };
  const handleSubmit = () => {
    if (mode === 'create') {
      dispatch(
        createObject({
          name,
          description,
          ohraniki: JSON.stringify(keeper),
          ...(client ? { client_id: client.id } : {}),
          head_of_security: boss,
          periodicity: JSON.stringify(items.map(({ value }) => value)),
          dlitelnost: duration,
          keyset: JSON.stringify(key),
          address: JSON.stringify(address),
          grafik_klininga: JSON.stringify(days),
          chart: JSON.stringify(items),
          company_id: user.company_id,
        })
      );
    } else if (mode === 'edit') {
      dispatch(
        updateObject({
          name,
          description,
          ...(client ? { client_id: client.id } : {}),
          ohraniki: JSON.stringify(keeper),
          head_of_security: boss,
          periodicity: JSON.stringify(items.map(({ value }) => value)),
          dlitelnost: duration,
          keyset: JSON.stringify(key),
          address: JSON.stringify(address),
          grafik_klininga: JSON.stringify(days),
          chart: JSON.stringify(items),
          company_id: user.company_id,
          id: item!.id,
        })
      );
    }
    handleClose();
  };

  useEffect(() => {
    if (mode === 'edit' && item) {
      setBoss(item.head_of_security);
      setName(item.name);
      setDays(JSON.parse(item.grafik_klininga));
      setItems(JSON.parse(item.chart));
      setDescription(item.description);
      setDuration(item.dlitelnost);
      setKey(item.keyset[0] === '[' ? JSON.parse(item.keyset) : item.keyset);
      setAddress(item.address[0] === '[' ? JSON.parse(item.address) : item.address);
      setQuantity(item.periodicity);
      setClient(clients.find(client => client.id === item.client_id) as IUserConf);
      setKeeper(JSON.parse(item.ohraniki));
    }
  }, [item]);

  return (
    <div className='px-1'>
      <h6>
        {mode === 'create'
          ? 'Добавить'
          : mode === 'edit'
            ? 'Редактировать'
            : 'Просмотр'}
      </h6>
      <div className='mb-2'>
        {/* <p className='mb-1 text-sm'>Наименование</p> */}
        <div>
          <Input
            value={name}
            handleChange={handleNameChange}
            placeholder='Наименование'
          />
        </div>
      </div>
      <div className='mb-2'>
        {/* <p className='mb-1 text-sm'>Описание</p> */}
        <div>
          <Input
            value={description}
            handleChange={handleDescriptionChange}
            placeholder='Описание'
          />
        </div>
      </div>
      <div className='mb-2'>
        {/* <div className='mb-1'>Охраники</div> */}
        <div>
          <Select
            placeholder='Выберите заказчика'
            options={clients.map((client) => ({
              label: client.name,
              value: client,
            }))}
            handleChange={handleClientChange}
            value={client ? { label: client?.name } : false}
            multi={false}
          />
        </div>
      </div>
      <div className='mb-2'>
        {/* <div className='mb-1'>Охраники</div> */}
        <div>
          <Select
            placeholder='Выберите охранника'
            options={employees.map((emp) => ({
              label: emp.name,
              value: emp.id,
            }))}
            handleChange={handleKeeperChange}
            value={keeper}
            multi={true}
          />
        </div>
      </div>
      <div className='mb-2'>
        {/* <p className='mb-1 text-sm'>Начальник охраны</p> */}
        <div>
          {/* <Input
            value={boss}
            handleChange={handleBossChange}
            placeholder='Начальник охраны'
          /> */}
          <Select
            placeholder='Начальник охраны'
            options={adminEmployees.map((emp) => ({
              label: emp.name,
              value: emp.id,
            }))}
            value={adminEmployees
              .map((emp) => ({
                label: emp.name,
                value: emp.id,
              }))
              .find((i: any) => i.value == boss)}
            handleChange={handleBossChange}
          />
        </div>
      </div>
      {/* <div className='mb-2'>
        {/* <p className='mb-1 text-sm'>Периодичность обхода</p>
        <div>
          <Input
            value={quantity}
            handleChange={handleQuantityChange}
            placeholder='Периодичность обхода'
          />
        </div>
      </div> */}
      <div className='mb-2'>
        {/* <p className='mb-1 text-sm'>Длительность</p> */}
        <div>
          <Input
            value={duration}
            handleChange={handleDurationChange}
            placeholder='Длительность (чч:мм)'
            type='time'
          />
        </div>
      </div>
      {/* <div className='mb-2'>
        <p className='mb-1 text-sm'>График клининга</p>
        <CleaningSchedulerForm items={days} setItems={setDays} />
      </div> */}
      <div className='mb-2'>
        <p className='mb-1 text-sm'>График обходов</p>
        <WorkScheduleForm
          handleCreate={handleCreate}
          handleDelete={handleDelete}
          items={items}
          setItems={setItems}
        />
      </div>
      <div className='mb-3'>
        {/* <p className='mb-1 text-sm'>Наборы ключей</p> */}
        {/* <div>
          <Input
            value={key}
            handleChange={handleKeyChange}
            placeholder='Наборы ключей'
          />
        </div> */}
        <AddKeysForm items={key} handleChange={handleKeyChange} />
        <AddAddressesForm items={address} handleChange={handleAddressChange} />
      </div>
      <button className='btn btn-info w-100' onClick={handleSubmit}>
        Cохранить
      </button>
    </div>
  );
};

export default ModalObject;
