import React, {useState} from "react";
import {IEventConf} from "../../store/slices/eventSlice/eventSlice";
import exp from "node:constants";
import {SERVER_URL} from "../../helpers/const";
import {useAppSelector} from "../../store/store";

interface ICardEvent {
  item: IEventConf;
}

export const EventRow: React.FC<ICardEvent> = ({item}) => {
  const employees = useAppSelector((state) => state.employee.data);
  const [active, setActive] = useState(false);

  const getLabel = (key: string) => {
    switch (key) {
      case 'comment':
        return 'Комментарий';
      case 'event':
        return 'Событие';
      case 'photos':
        return 'Фотографии';
      case 'toWho':
        return 'Адресовано';
      case 'reason':
        return 'Цель/Причина';
      case 'apartment':
        return 'Номер квартиры';
      case 'report':
        return 'Замечания';
      case 'fixed':
        return 'Возможно устранить';
      case 'act':
        return 'Акт выявленного нарушения';
      case 'name':
        return 'ФИО';
      case 'room':
        return 'Помещение';
      case 'keys':
        return 'Ключи';
      case 'polisValue':
        return 'Данные полиса ОСАГО';
      case 'address':
        return 'Адрес';
      case 'phone':
        return 'Номер телефона';
      case 'time':
        return 'Время';
      case 'reported':
        return 'На кого жалоба';
      case 'building':
        return 'Дом';
      case 'sound':
        return 'Характер звука';
      case 'result':
        return 'Результат о проделанной работе';
      case 'to_who':
        return 'Обратился';
      case 'allowed':
        return 'Получил разрешение';
      case 'audio':
        return 'Аудио';
      case 'total_time':
        return 'Время';
      case 'distance':
        return 'Расстояние';
      case 'events_count':
        return 'Выявлено событий/замечаний';
      case 'street':
        return 'Улица';
      case 'type':
        return 'Тип транспорта';
      case 'passport':
        return 'Номер водительского удостоверения';
      case 'property':
        return 'Порча имущества';
      case 'strangers':
        return 'Посторонние';
      case 'fight':
        return 'Драка';
      case 'police':
        return 'Вызвал полицию';
      case 'conversation':
        return 'Провел беседу';
      case 'parking':
        return 'Водитель перепарковался';
      case 'instruction':
        return 'Инструктаж';
      case 'door':
        return 'Целостность входной двери';
      case 'garbage':
        return 'Отсутствие мусора';
      case 'wall':
        return 'Целостность стен';
      case 'elevator':
        return 'Исправность лифта';
      case 'coord':
        return 'Геолокация';
      case 'fromBuilding':
        return 'Дом уведомителя';
      case 'fromApartment':
        return 'Квартира уведомителя';
      case 'fromStreet':
        return 'Улица уведомителя';
      case 'datetime_end':
        return 'Дата и время конца';
      case 'number':
        return 'Номер';
      case 'numberType':
        return 'Тип номера';
      case 'trafficAccidentLeave':
        return 'Оставление места ДТП';
      case 'notifyList':
        return 'Известил(а)';
      default:
        return key;
    }
  };
  const getInfo = (info: string) => {
    if (info[0] === '{') {
      let items = [];
      let data: any = {};
      try {
        data = JSON.parse(info);
      } catch (error) {
        console.log(error);
      }
      for (let key in data) {
        if (typeof data[key] === 'string' || typeof data[key] === 'number') {
          items.push({value: data[key], key});
        } else if (typeof data[key] === 'boolean') {
          items.push({value: data[key] === true ? 'Да' : 'Нет', key});
        } else {
          items.push({value: data[key], key});
        }
      }
      // Special properties:
      items = items.filter(({key}) => !['step', 'alerted', 'datetime_start', 'datetime_end'].includes(key))
      //
      return items.map((i, index) => (
        <div
          className='d-flex align-items-center justify-content-between flex-wrap'
          key={index}
        >
          <span className='text-sm'>{getLabel(i.key)}</span>
          {i.key === 'audio' ? (
            <audio
              src={`${SERVER_URL}/get-file/${i.value}`}
              controls
              className='w-100 mt-1 mb-2'
              style={{height: 30}}
            ></audio>
          ) : i.key === 'keys' || i.key === 'notifyList' ? (
            <div>
              {i.value.map((k: any, inx: any) => (
                <div className='text-sm' key={inx}>
                  {k.type} - {k.value === true ? 'да' : 'нет'}
                </div>
              ))}
            </div>
          ) : (
            <span className='text-sm mw-100'>{i.value || '-'}</span>
          )}
        </div>
      ));
    } else {
      return <span className='text-sm'>{info}</span>;
    }
  };
  return (
    <tr className="cursor-pointer" onClick={() => {
      setActive(!active);
    }}>
      <td>
        <div>
          {item.event}
        </div>
        {
          active && (
            <div>
              {getInfo(item.info!)}
            </div>
          )
        }


      </td>
      <td>{employees.find((i) => i.id == item.guard_id)?.name ||
        item.guard_id}</td>
      <td>{new Date(item.create_at!).toLocaleString('ru-RU')}</td>
      <td>{item.info_subject}</td>
    </tr>
  )
}